export default function Banner() {
  return (
    <div>
      <div className="flex items-center justify-center bg-white px-6 py-2.5 sm:px-3.5">
        {/* Flexbox with justify-center */}
        <p className="text-sm leading-6 text-black flex items-center whitespace-nowrap">
          <a
            href="https://www.google.com/maps/place/Dreirosen+Apotheke/@47.5688986,7.5906421,17z/data=!4m6!3m5!1s0x4791b9a3ba68615b:0xed2e0461c6758e53!8m2!3d47.5688988!4d7.5906717!16s%2Fg%2F1tlvnfwl?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <svg
              className="inline h-5 w-5 text-black mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 5.2 11.25 6.6 12.88a1.25 1.25 0 001.8 0C13.8 20.25 19 14.25 19 9c0-3.87-3.13-7-7-7zm0 9.25a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5z" />
            </svg>
            <span className="hover:underline">Standort</span>
          </a>
          <span className="mx-4">|</span>
          <a href="tel:+1234567890" className="flex items-center">
            <svg
              className="inline h-5 w-5 text-black mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M6.62 10.79a15.564 15.564 0 006.59 6.59l2.2-2.2a1.002 1.002 0 011.11-.27 11.493 11.493 0 003.59.61c.55 0 1 .45 1 1V20a1 1 0 01-1 1c-9.39 0-17-7.61-17-17a1 1 0 011-1h3.5c.55 0 1 .45 1 1 0 1.25.21 2.47.61 3.59.14.34.05.74-.27 1.11l-2.2 2.2z" />
            </svg>
            <span className="hover:underline">+41 61 691 74 88</span>
          </a>
        </p>
      </div>

      {/* Divider */}
      <div className="mx-6 border-t border-gray-300"></div>
    </div>
  );
}
