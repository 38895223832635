import { useState, useMemo } from 'react';

const labCategories = {
  "Blutanalysen": [
    {
      name: "Eisenmangel, Folsäure + Vitamin B12",
      category: "Blutanalyse",
      analyses: ["Hämoglobin", "Ferritin", "CRP", "Folsäure", "aktiv B12 (Holotranscobalamin)"],
      description: "Umfassende Analyse des Eisenhaushalts und wichtiger Vitamine"
    },
    {
      name: "Grosses Blutbild",
      category: "Blutanalyse",
      analyses: ["Häm V", "5-Part Diff und Retikulozyten"],
      description: "Detaillierte Analyse aller Blutbestandteile"
    },
    {
      name: "CRP high sensitiv",
      category: "Blutanalyse",
      analyses: ["CRP high sensitiv"],
      description: "Präzise Messung des C-reaktiven Proteins"
    },
    {
      name: "Blutgruppe inkl. Blutgruppenausweis",
      category: "Blutanalyse",
      analyses: ["BG und Rhesusfaktor inkl. BG-Ausweis an Patienten"],
      description: "Bestimmung der Blutgruppe mit offiziellem Ausweis"
    }
  ],
  "Organfunktion": [
    {
      name: "Leber Check",
      category: "Organfunktion",
      analyses: ["ALT", "AST", "GGT"],
      description: "Überprüfung der Leberfunktion"
    },
    {
      name: "Nieren Check",
      category: "Organfunktion",
      analyses: ["Kreatinin", "Harnsäure", "Harnstoff", "eGFR"],
      description: "Umfassende Nierendiagnostik"
    }
  ],
  "Stoffwechsel & Ernährung": [
    {
      name: "Lipidstatus gross",
      category: "Stoffwechsel",
      analyses: ["Apo A1", "Apo B", "Cholesterin", "HDL", "LDL", "Lipoprotein (a)", "Triglyceride"],
      description: "Ausführliche Analyse der Blutfette"
    },
    {
      name: "Lipidstatus klein",
      category: "Stoffwechsel",
      analyses: ["Cholesterin", "HDL", "LDL"],
      description: "Basis-Analyse der Blutfette"
    },
    {
      name: "Vitamin D",
      category: "Stoffwechsel",
      analyses: ["Vitamin D 25-OH"],
      description: "Bestimmung des Vitamin D Spiegels"
    },
    {
      name: "Spurenelemente",
      category: "Stoffwechsel",
      analyses: ["Zink", "Selen", "Kupfer"],
      description: "Analyse wichtiger Spurenelemente"
    },
    {
      name: "Vegetarisch & Vegan",
      category: "Stoffwechsel",
      analyses: ["Häm I", "Ferritin", "CRP", "aktiv B12", "Folsäure", "Vitamin D3", "Vitamin B6", "B2", "Selen", "Biotin"],
      description: "Spezielle Analyse für pflanzliche Ernährung"
    },
    {
      name: "Omega-6/3 Fettsäuren",
      category: "Stoffwechsel",
      analyses: ["Omega-3 Fettsäuren", "Omega-6 Fettsäuren", "Fettsäureverhältnis"],
      description: "Analyse der Fettsäureverteilung"
    }
  ],
  "Hormone & Spezialanalysen": [
    {
      name: "Zyklusstörungen",
      category: "Hormone",
      analyses: ["Östradiol", "FSH", "LH", "Prolaktin", "Testosteron", "DHEA-S", "AMH", "SHBG"],
      description: "Hormonelle Abklärung bei Zyklusstörungen"
    },
    {
      name: "Wechseljahre",
      category: "Hormone",
      analyses: ["TSH", "Östradiol", "FSH", "AMH", "HbA1c"],
      description: "Hormonelle Abklärung in den Wechseljahren"
    },
    {
      name: "Haarausfall",
      category: "Hormone",
      analyses: ["Ferritin", "CRP", "aktiv B12 Holotranscobalamin", "Zink", "Protein gesamt", "TSH", "fT3", "fT4", "DHEA-S", "Prolaktin", "Testosteron gesamt, frei und bioaktiv"],
      description: "Umfassende Analyse bei Haarausfall"
    },
    {
      name: "Schilddrüsenunterfunktion",
      category: "Hormone",
      analyses: ["TSH", "fT3", "fT4"],
      description: "Analyse der Schilddrüsenfunktion"
    }
  ],
  "Präventivmedizin": [
    {
      name: "Check-up",
      category: "Präventivmedizin",
      analyses: ["Ferritin", "CRP high-sensitiv", "TSH", "ALT", "AST", "GGT", "Kreatinin", "eGFR", "Cholesterin", "HDL", "LDL", "Hämatogramm II", "HbA1c"],
      description: "Umfassende Gesundheitsvorsorge"
    },
    {
      name: "Knochengesundheit",
      category: "Präventivmedizin",
      analyses: ["CTX b-CrossLaps", "AP-Knochen", "Alk. Phosphatase", "Calcium", "Phosphat"],
      description: "Analyse der Knochengesundheit"
    }
  ],
  "Allergien & Unverträglichkeiten": [
    {
      name: "Allergy Explorer (295 Einzelallergene)",
      category: "Allergien",
      analyses: ["Bäume & Gräser", "Milben", "Nahrungsmittel", "Insektengifte", "Tiere", "Pilzsporen"],
      description: "Umfassende Allergie-Testung"
    },
    {
      name: "Zölikaie (Gluten-Intoleranz)",
      category: "Allergien",
      analyses: ["IgA total", "Anti-Transglutaminase"],
      description: "Diagnose von Gluten-Unverträglichkeit"
    }
  ],
  "Infektionskrankheiten": [
    {
      name: "HIV",
      category: "Infektionen",
      analyses: ["HIV 1+2 Ag/Ak 4. Generation"],
      description: "HIV-Test"
    },
    {
      name: "HIV + Lues",
      category: "Infektionen",
      analyses: ["HIV 1+2 Ag/Ak 4. Generation", "Lues Syphilis Screen"],
      description: "Kombinierter Test auf HIV und Syphilis"
    },
    {
      name: "Hepatitis B Impftiter",
      category: "Infektionen",
      analyses: ["Anti-HBs Antikörper"],
      description: "Überprüfung des Impfschutzes gegen Hepatitis B"
    }
  ],
  "Verdauung & Darm": [
    {
      name: "Durchfall Check",
      category: "Verdauung",
      analyses: ["Testung auf 22 infektiöse Durchfallerreger (Bakterien, Viren, Parasiten)"],
      description: "Umfassende Analyse bei Durchfallerkrankungen"
    },
    {
      name: "Calprotectin im Stuhl",
      category: "Verdauung",
      analyses: ["Calprotectin"],
      description: "Entzündungsmarker im Stuhl"
    },
    {
      name: "Darmkrebs Vorsorge",
      category: "Verdauung",
      analyses: ["Okkultes Blut"],
      description: "Früherkennung von Darmkrebs"
    }
  ],
  "Genetik & Personalisierte Medizin": [
    {
      name: "Pharmakogenetik",
      category: "Genetik",
      analyses: ["Rückschlüsse auf die individuelle Metabolisierung von über 180 Wirkstoffen aus Herz-Kreislauf, Schmerz, Diabetes, Onkologie und Psychiatrie"],
      description: "Analyse der individuellen Medikamentenverwertung"
    },
    {
      name: "Ernährungsgenetik Selbstzahler",
      category: "Genetik",
      analyses: ["Rückschlüsse auf die individuelle genetische Veranlagung bezüglich Ernährung und Gewichtsmanagement"],
      description: "Genetische Analyse für personalisierte Ernährung"
    }
  ]
};

export default function Services() {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleModalOpen = (profile) => {
      setSelectedProfile(profile);
      setIsModalOpen(true);
    };
  
    // Filter logic
    const filteredCategories = useMemo(() => {
      if (selectedCategory === 'all' && !searchTerm) {
        return labCategories;
      }
  
      return Object.entries(labCategories).reduce((acc, [category, profiles]) => {
        // Filter by category if selected
        if (selectedCategory !== 'all' && category !== selectedCategory) {
          return acc;
        }
  
        // Filter by search term
        const filteredProfiles = profiles.filter(profile =>
          profile.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          profile.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          profile.analyses.some(analysis => 
            analysis.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
  
        if (filteredProfiles.length > 0) {
          acc[category] = filteredProfiles;
        }
  
        return acc;
      }, {});
    }, [selectedCategory, searchTerm]);
  
    // Get total count of all tests
    const totalTests = Object.values(labCategories).reduce(
      (sum, profiles) => sum + profiles.length, 
      0
    );
  
    return (
      <div className="bg-white py-12">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

          {/* Search and Filter Section */}
          <div className=" max-w-3xl mx-auto">
            <div className="flex flex-col sm:flex-row gap-4">
              {/* Search Input */}
              <div className="relative flex-1">
                <input
                  type="text"
                  className="w-full rounded-md border border-gray-300 px-4 py-2 pl-10 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  placeholder="Suchen Sie nach Tests, Analysen oder Beschreibungen..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <svg 
                  className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path 
                    fillRule="evenodd" 
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" 
                    clipRule="evenodd" 
                  />
                </svg>
              </div>
  
              {/* Category Filter */}
              <select
                className="rounded-md border border-gray-300 px-4 py-2 bg-white"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="all">Alle Kategorien ({totalTests})</option>
                {Object.entries(labCategories).map(([category, profiles]) => (
                  <option key={category} value={category}>
                    {category} ({profiles.length})
                  </option>
                ))}
              </select>
            </div>
  
            {/* Search Results Count */}
            <div className="mt-4 text-sm text-gray-500">
              {Object.values(filteredCategories).reduce((sum, profiles) => sum + profiles.length, 0)} Tests gefunden
            </div>
          </div>
        {/* Categories and Tests Display */}
        <div className="mt-16 space-y-20">
          {Object.entries(filteredCategories).map(([category, profiles]) => (
            <div key={category} className="relative">
              <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-start">
                  <span className="bg-white pr-8 flex items-center">
                    <h3 className="text-2xl font-semibold text-gray-900">{category}</h3>
                    <span className="ml-4 h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center">
                      <span className="text-blue-600 text-sm font-medium">{profiles.length}</span>
                    </span>
                  </span>
                </div>
              </div>
              
              <div className="mt-8">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {profiles.map((profile, index) => (
                    <div
                      key={index}
                      className="relative group bg-white p-6 rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 border border-gray-100 hover:border-blue-100"
                    >

                      <div className="mb-6">
                        <h4 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                          {profile.name}
                        </h4>
                        <p className="text-sm text-gray-500 mt-1">{profile.description}</p>
                        
                        {/* Analysis Count */}
                        <div className="mt-3 flex items-center text-sm text-gray-500">
                          <svg className="mr-1.5 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                          </svg>
                          {profile.analyses.length} Analysen
                        </div>

                        {/* Preview of Analyses */}
                        <div className="mt-4">
                          <div className="flex flex-wrap gap-2">
                            {profile.analyses.slice(0, 3).map((analysis, idx) => (
                              <span
                                key={idx}
                                className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
                              >
                                {analysis}
                              </span>
                            ))}
                            {profile.analyses.length > 3 && (
                              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-600">
                                +{profile.analyses.length - 3} weitere
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <button 
                        onClick={() => handleModalOpen(profile)}
                        className="w-full mt-4 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 transition-colors group-hover:bg-blue-100"
                      >
                        Details anzeigen
                        <svg className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          {/* No Results Message */}
          {Object.keys(filteredCategories).length === 0 && (
            <div className="text-center py-12">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">Keine Ergebnisse gefunden</h3>
              <p className="mt-1 text-sm text-gray-500">
                Versuchen Sie es mit anderen Suchbegriffen oder wählen Sie eine andere Kategorie.
              </p>
              <div className="mt-6">
                <button
                  onClick={() => {
                    setSearchTerm('');
                    setSelectedCategory('all');
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                >
                  Suche zurücksetzen
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Modal */}
        {isModalOpen && selectedProfile && (
          <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setIsModalOpen(false)}></div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                <div className="mt-3 sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    {selectedProfile.name}
                  </h3>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500 mb-4">
                      {selectedProfile.description}
                    </p>
                    <h4 className="text-sm font-medium text-gray-900 mb-2">Durchgeführte Analysen:</h4>
                    <ul className="space-y-2">
                      {selectedProfile.analyses.map((analysis, idx) => (
                        <li key={idx} className="text-sm text-gray-600 flex items-center gap-2">
                          <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                          {analysis}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:text-sm"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Schließen
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}  