'use client';

import { motion } from 'framer-motion';

export default function ValueProposition() {
  return (
    <section className="py-10 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="relative rounded-3xl bg-white shadow-xl overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-[#51A550]/10 to-blue-100/20 opacity-50" />
          
          <div className="relative px-8 py-16 sm:px-16 sm:py-24">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              {/* Left side - Text content */}
              <div>
                <motion.h2
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                  className="text-4xl font-bold text-gray-900 mb-6"
                >
                  Eifach, schnell und alles uf einere Plattform
                </motion.h2>
                
                <motion.p
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                  className="text-xl text-gray-600 leading-relaxed"
                >
                  Mit em perfekte Mix us Shop und Labor Diagnostik, biete mir dir alles, 
                  was du für dini Gsundheit bruchsch. Vom tägliche Pflegeprodukt bis zu 
                  präzise Diagnosen, alles isch uf ei Plattform verfügbar.
                </motion.p>
              </div>

              {/* Right side with phone mockup and floating elements */}
              <div className="relative h-[600px]">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.8 }}
                  className="absolute inset-0"
                >
                  {/* Phone frame */}
                  <div className="relative w-[280px] h-[570px] mx-auto">
                    <div className="absolute inset-0 bg-gray-900 rounded-[3rem] shadow-2xl" />
                    <div className="absolute inset-2 bg-white rounded-[2.7rem] overflow-hidden">
                      {/* Phone screen content */}
                      <div className="h-full bg-gradient-to-b from-[#51A550]/5 to-blue-100/10">
                        {/* Status bar */}
                        <div className="px-4 py-2 flex justify-between items-center border-b border-gray-100">
                          <div className="flex space-x-1">
                            <div className="w-1.5 h-1.5 rounded-full bg-[#51A550]"></div>
                            <div className="w-1.5 h-1.5 rounded-full bg-[#51A550] opacity-60"></div>
                            <div className="w-1.5 h-1.5 rounded-full bg-[#51A550] opacity-30"></div>
                          </div>
                          <span className="text-xs font-medium text-gray-600">12:30</span>
                        </div>

                        {/* App content */}
                        <div className="p-4 space-y-4">
                          {/* Welcome section */}
                          <div className="bg-white rounded-xl p-4 shadow-sm">
                            <h3 className="text-sm font-semibold text-gray-900">Willkomme bi de</h3>
                            <h2 className="text-lg font-bold text-[#51A550]">Dreirosen Apotheke</h2>
                            <p className="text-xs text-gray-600 mt-1">Mit uns blibts Glaibasel gsund!</p>
                          </div>

                          {/* Services section */}
                          <div className="space-y-3">
                            <motion.div
                              whileHover={{ x: 5 }}
                              className="bg-white rounded-xl p-4 shadow-sm cursor-pointer"
                            >
                              <h3 className="text-sm font-semibold text-[#51A550]">Labor Diagnostik</h3>
                              <p className="text-xs text-gray-600 mt-1">
                                Modernsti Labordiagnostik für Ihri Gsundheit - schnell und zuverlässig
                              </p>
                            </motion.div>

                            <motion.div
                              whileHover={{ x: 5 }}
                              className="bg-white rounded-xl p-4 shadow-sm cursor-pointer"
                            >
                              <h3 className="text-sm font-semibold text-[#51A550]">Online Shop</h3>
                              <p className="text-xs text-gray-600 mt-1">
                                +100,000 Produkt für Gsundheit, Schönheit und Pfleg - alles uf eim Blick
                              </p>
                            </motion.div>

                            <motion.div
                              whileHover={{ x: 5 }}
                              className="bg-white rounded-xl p-4 shadow-sm cursor-pointer"
                            >
                              <h3 className="text-sm font-semibold text-[#51A550]">Fachberatig</h3>
                              <p className="text-xs text-gray-600 mt-1">
                                Persönlichi Beratig vo üsem qualifizierte Team für Ihri individuelli Bedürfnis
                              </p>
                            </motion.div>

                            <motion.div
                              whileHover={{ x: 5 }}
                              className="bg-white rounded-xl p-4 shadow-sm cursor-pointer"
                            >
                              <h3 className="text-sm font-semibold text-[#51A550]">Express Check-up</h3>
                              <p className="text-xs text-gray-600 mt-1">
                                Schnelli Gsundheitschecks ohni Termin - für Ihri Sicherheit
                              </p>
                            </motion.div>
                          </div>

                          {/* Contact section */}
                          <div className="bg-white rounded-xl p-4 shadow-sm mt-auto">
                            <h3 className="text-sm font-semibold text-gray-900">Kontakt</h3>
                            <p className="text-xs text-gray-600 mt-1">Klybeckstrasse 140, 4057 Basel</p>
                            <p className="text-xs text-[#51A550] mt-1">+41 61 691 74 88</p>
                          </div>

                          {/* Bottom navigation */}
                          <div className="absolute bottom-0 inset-x-0 bg-white border-t border-gray-100 p-2">
                            <div className="flex justify-around">
                              <div className="text-[#51A550]">
                                <svg className="w-5 h-5 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                                  <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                                <span className="text-[10px] font-medium">Home</span>
                              </div>
                              <div className="text-gray-400">
                                <svg className="w-5 h-5 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                                  <path d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                </svg>
                                <span className="text-[10px] font-medium">Shop</span>
                              </div>
                              <div className="text-gray-400">
                                <svg className="w-5 h-5 mx-auto" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                                  <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <span className="text-[10px] font-medium">Profil</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Floating elements */}
                  <motion.div
                    animate={{ 
                      y: [0, -10, 0],
                      rotate: [0, 5, 0]
                    }}
                    transition={{
                      duration: 4,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                    className="absolute top-20 right-20 w-16 h-16 bg-[#51A550]/10 rounded-xl"
                  />
                  <motion.div
                    animate={{ 
                      y: [0, 10, 0],
                      rotate: [0, -5, 0]
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                    className="absolute bottom-32 left-20 w-12 h-12 bg-blue-100/30 rounded-full"
                  />
                  <motion.div
                    animate={{ 
                      y: [0, -8, 0],
                      x: [0, 5, 0]
                    }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                    className="absolute top-40 left-10 w-8 h-8 bg-[#51A550]/20 rounded-lg"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}