import { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { fetchCategories } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';

// Move staticCategories outside the component to avoid triggering unnecessary re-renders
const staticCategories = [
  "Medikamente & Behandlung",
  "Ernährung & Gesundheit",
  "Baby & Eltern",
  "Beauty & Körperpflege",
  "Haushalt & Reinigung",
  "Sanitätsartikel & Krankenpflege",
];

export default function Categories() {
  const [fetchedCategories, setFetchedCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndMapCategories = async () => {
      try {
        const response = await fetchCategories();
        const fetchedData = response.data;

        const categoryMapping = {
          "Medikamente & Behandlung": ["PainFever", "FluColds"],
          "Ernährung & Gesundheit": ["Nutrition"],
          "Baby & Eltern": ["MotherChild"],
          "Beauty & Körperpflege": ["FacialSkinCareMakeUp", "SkinHairNails"],
          "Haushalt & Reinigung": ["HouseGarden"],
          "Sanitätsartikel & Krankenpflege": ["FirstAidDressingMat"],
        };

        const mappedCategories = {};

        staticCategories.forEach((staticCategory) => {
          const relevantCategories = categoryMapping[staticCategory];
          const matchingCategories = fetchedData.filter((category) =>
            relevantCategories.includes(category.category.en)
          );
          mappedCategories[staticCategory] = matchingCategories;
        });

        setFetchedCategories(mappedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setIsLoading(false); // Set loading to false after data fetch
      }
    };

    fetchAndMapCategories();
  }, []); // Dependency array includes no unnecessary dependencies

  const handleSubcategoryClick = (subcategory) => {
    const cleanSubcategoryId = subcategory.en.trim();
    sessionStorage.setItem('germanName', subcategory.de);
    navigate(`/categories/${cleanSubcategoryId}/products`, {
      replace: true
    });
  };

  return (
    <div className="relative z-40">
      <div className="mx-6 border-t border-gray-300"></div>
      <div className="flex items-center justify-between px-6 py-4 sm:px-3.5">
        <div className="flex items-center justify-center gap-x-12 flex-wrap w-full">
          {staticCategories.map((categoryName, index) => (
            <div key={index} className="text-sm font-medium text-[#4D7F6E] relative">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-700">
                    {categoryName}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="-mr-1 h-5 w-5 text-gray-400"
                    >
                      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z" />
                    </svg>
                  </Menu.Button>
                </div>

                <Menu.Items
                  className="absolute left-0 z-50 mt-2 w-66 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    {isLoading ? (
                      <div className="px-4 py-2 text-gray-500 text-sm">Loading...</div>
                    ) : (
                      fetchedCategories[categoryName]?.map((category, idx) => (
                        <div key={idx}>
                          <ul className="px-1 space-y-1">
                            {category.subcategories.map((subcategory, subIdx) => (
                              <Menu.Item key={subIdx}>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleSubcategoryClick(subcategory)}
                                    className={`block w-full text-left px-4 py-2 text-sm text-gray-700 ${
                                      active ? 'bg-gray-100 text-gray-900' : ''
                                    }`}
                                  >
                                    {subcategory.de}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </ul>
                        </div>
                      ))
                    )}
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          ))}
        </div>
      </div>
      <div className="mx-6 border-t border-gray-300"></div>
    </div>
  );
}
