import { useState } from 'react'
import Home from './Home'
import Services from './Services'
import AppointmentForm from './AppointmentForm'
import ImportantInfo from './ImportantInfo'
import VacDetails from './VacDetails'
import LabHero from './LabHero'
import WhyGetTested from './WhyGetTested'

const steps = [
  { name: 'Übersicht', description: 'Unser Labor & Leistungen', href: '#', status: 'current' },
  { name: 'Dienstleistungen', description: 'Impfungen & Labordiagnostik', href: '#' },
  { name: 'Terminbuchung', description: 'Online Terminvereinbarung', href: '#', status: 'upcoming' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs() {
  const [currentTab, setCurrentTab] = useState(0)

  const renderView = () => {
    switch (currentTab) {
      case 0:
        return (
          <>
            <LabHero />
            <VacDetails />
            <WhyGetTested />
            <ImportantInfo />
          </>
        )
      case 1:
        return <Services />
      case 2:
        return <AppointmentForm />
      default:
        return <Home />
    }
  }

  return (
    <div>
      <div className="border-b border-t border-gray-200">
        <nav aria-label="Progress" className="mx-auto max-w-8xl px-0 sm:px-4 lg:px-8">
          <ol
            role="list"
            className="flex rounded-none border-l border-r border-gray-200"
          >
            {steps.map((step, stepIdx) => (
              <li 
                key={stepIdx} 
                className="relative overflow-hidden flex-1 cursor-pointer"
                onClick={() => setCurrentTab(stepIdx)}
              >
                <div
                  className={classNames(
                    'overflow-hidden border border-gray-200 lg:border-0 h-full',
                  )}
                >
                  <div className="group h-full relative">
                    <span
                      aria-hidden="true"
                      className={classNames(
                        "absolute left-0 bottom-0 h-1 w-full z-10",
                        currentTab === stepIdx ? 'bg-[#EFF6FF]' : 'bg-transparent group-hover:bg-gray-200'
                      )}
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'flex h-full items-center justify-center lg:justify-start px-2 sm:px-3 lg:px-6 py-3 lg:py-5 text-sm font-medium',
                      )}
                    >
                      <span className="flex min-w-0 flex-col items-center lg:items-start">
                        <span className={classNames(
                          "text-sm font-medium text-center lg:text-left truncate max-w-full",
                          currentTab === stepIdx ? "text-blue-800" : "text-gray-500"
                        )}>
                          {step.name}
                        </span>
                        <span className="hidden lg:block text-sm font-medium text-gray-500">{step.description}</span>
                      </span>
                    </span>
                  </div>
                </div>

                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div aria-hidden="true" className="absolute inset-0 left-0 top-0 hidden w-3 lg:block">
                      <svg
                        fill="none"
                        viewBox="0 0 12 82"
                        preserveAspectRatio="none"
                        className="size-full text-gray-300"
                      >
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      
      {/* View Container */}
      <div className="mt-0">
        {renderView()}
      </div>
    </div>
  )
}