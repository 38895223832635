import { motion } from 'framer-motion';

export default function BlogSection() {
  const posts = [
    {
      title: 'Saisonale Wellnesstipps: Gesundheit und Achtsamkeit im Winter',
      href: '/fasten-konvertiert',
      category: { name: 'Dreirosen Apotheke' },
      description:
        'Für viele Menschen ist die Herbst- und Winterzeit mit mehr Trägheit verbunden. Weniger Aktivität ist vorhanden, da die Dunkelheit uns oft am Morgen zur Arbeit begleitet und am Abend wieder in Empfang nimmt...',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      imageUrl: 'images/resized_food3.webp',
    },
    {
      title: 'ENIGMA SCHLAF',
      href: '/enigma-schlaf',
      category: { name: 'Dreirosen Apotheke' },
      description:
        'Schlafmangel macht, schreibt Peter Spork, nicht nur krank, sondern auch dumm. Denn eine der Hauptaufgaben des Schlafes scheint darin zu bestehen, am Tag Gelerntes dauerhaft zu speichern. Die Forscher interessierten sich dabei vor allem für den Tiefschlaf...',
      date: 'Mar 10, 2020',
      datetime: '2020-03-10',
      imageUrl: 'images/resized_sleep3.webp',
    },
    {
      title: 'Wie komplett ist Ihre Reiseapotheke?',
      href: 'Ferien-konvertiert',
      category: { name: 'Dreirosen Apotheke' },
      description:
        'Bitte prüfen Sie Ihre Reiseapotheke anhand unserer Checkliste. Darin finden Sie eine Auswahl an Produkten, welche wir Ihnen für Ihre Reise empfehlen. Bei Fragen oder zur kostenlosen Zusammenstellung stehen wir Ihnen natürlich gerne zur Seite...',
      date: 'Feb 12, 2020',
      datetime: '2020-02-12',
      imageUrl: 'images/resized_travel1.webp',
    },
  ];

  return (
    <section className="py-12 md:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          className="mx-auto max-w-5xl lg:mx-0 mb-8 md:mb-16 relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Gesundheitsartikel
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Hier finden Sie einige Gesundheitsartikel, die Ihnen helfen, Ihr Wohlbefinden zu fördern und mehr über verschiedene Aspekte der Gesundheit des Lebensstils zu erfahren.
          </p>
          <div className="absolute inset-0 -z-10">
            <div className="absolute inset-0 bg-blue-100 rounded-full blur-3xl opacity-20 transform -translate-y-1/2"></div>
          </div>
        </motion.div>

        {/* Mobile View - Carousel */}
        <div className="block lg:hidden">
          <div className="relative overflow-hidden">
            <div className="flex overflow-x-auto snap-x snap-mandatory hide-scrollbar">
              {posts.map((post) => (
                <div 
                  key={post.title} 
                  className="flex-shrink-0 w-full snap-center px-2"
                >
                  <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl">
                    <div className="relative h-48">
                      <img
                        alt={`Bild für ${post.title}`}
                        src={post.imageUrl}
                        className="absolute inset-0 w-full h-full object-cover"
                        loading="lazy"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                      <div className="absolute bottom-4 left-4 right-4">
                        <p className="text-sm font-medium text-white/80">{post.category.name}</p>
                        <h3 className="mt-2 text-xl font-semibold text-white">{post.title}</h3>
                      </div>
                    </div>
                    <div className="p-4">
                      <p className="text-sm text-gray-600 line-clamp-3">{post.description}</p>
                      <a 
                        href={post.href}
                        className="mt-4 inline-flex items-center text-[#53a751] hover:text-[#2d5e2c] transition-colors duration-200"
                      >
                        Weiterlesen
                        <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Desktop View - Grid */}
        <div className="hidden lg:grid lg:grid-cols-3 gap-8">
          {posts.map((post) => (
            <div
              key={post.title}
              className="group bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl"
            >
              <div className="aspect-w-16 aspect-h-9 relative overflow-hidden">
                <img
                  alt={`Bild für ${post.title}`}
                  src={post.imageUrl}
                  className="object-cover w-full h-full group-hover:scale-110 transition-transform duration-500"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
              <div className="p-6">
                <p className="text-sm font-medium text-[#53a751]">{post.category.name}</p>
                <a href={post.href} className="mt-2 block">
                  <h3 className="text-xl font-semibold text-gray-900 group-hover:text-[#53a751] transition-colors duration-200">
                    {post.title}
                  </h3>
                  <p className="mt-3 text-base text-gray-500 line-clamp-3">{post.description}</p>
                </a>
                <div className="mt-4 flex items-center justify-end">
                  <span className="text-[#53a751] opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    Weiterlesen →
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </section>
  );
}
