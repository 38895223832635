import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchProductsBySubcategory, toggleFavoriteProduct } from '../../services/apiService';
import Pagination from './Pagination';
import CategorySidebar, { useCategories } from './CategorySidebar';

export default function CategoryResults() {
  const { subcategoryId } = useParams();
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [germanTitle, setGermanTitle] = useState('');
  const [favorite, setFavorite] = useState({});
  const navigate = useNavigate();
  const categories = useCategories();

  useEffect(() => {
    setCurrentPage(1);
  }, [subcategoryId]);

  useEffect(() => {
    console.log('CategoryResults mounted/updated:', {
      subcategoryId,
      location,
      currentUrl: window.location.href
    });
  
    const getProducts = async () => {
      try {
        setLoading(true);
        let germanName = sessionStorage.getItem('germanName');
        
        if (!germanName && Object.keys(categories).length > 0) {
          germanName = Object.values(categories)
            .flat()
            .find(category => 
              category.subcategories.some(sub => sub.en === subcategoryId)
            )?.subcategories.find(sub => sub.en === subcategoryId)?.de;
        }
    
        if (germanName) {
          setGermanTitle(germanName);
          const response = await fetchProductsBySubcategory(germanName, currentPage);
          console.log('Full API Response:', response);
          
          if (response.data?.products) {
            // Log the first product's articles to check regulations
            console.log('First product articles:', response.data.products[0]?.articles);
            console.log('First article regulations:', response.data.products[0]?.articles[0]?.regulations);
            
            setProducts(response.data.products);
            setTotalPages(response.data.totalPages || 1);
          } else {
            setProducts([]);
            setTotalPages(1);
          }
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]);
        setTotalPages(1);
      } finally {
        setLoading(false);
      }
    };
  
    getProducts();
  }, [subcategoryId, currentPage, categories]);

  const handleFavoriteClick = async (pharmacode) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/loginpage');
      return;
    }
  
    try {
      await toggleFavoriteProduct(pharmacode);
      setFavorite((prev) => ({
        ...prev,
        [pharmacode]: !prev[pharmacode],
      }));
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleViewProduct = (pharmacode) => {
    console.log('Navigating to product with pharmacode:', pharmacode);
    navigate(`/product/${pharmacode}`);
  };

  return (
    <div className="bg-white">
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-12">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            {germanTitle || subcategoryId}
          </h1>
        </div>
        
        <section aria-labelledby="products-heading" className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-4 gap-x-8">
          <CategorySidebar />

          <div className="lg:col-span-3">
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
              {loading ? (
                <div className="flex justify-center items-center h-64 col-span-full">
                  <svg
                    className="animate-spin h-10 w-10 text-[#3A7861]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                  <span className="ml-2 text-gray-500">Loading products...</span>
                </div>
              ) : products.length > 0 ? (
                products.map((product) =>
                  product.articles.map((article) => (
                    <div
                      key={article.pharmacode}
                      onClick={() => handleViewProduct(article.pharmacode)}
                      className="group relative border border-gray-200 rounded-lg bg-white shadow-sm hover:shadow-lg transition-shadow duration-200 cursor-pointer"
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFavoriteClick(article.pharmacode);
                        }}
                        className="absolute top-2 right-2 z-10"
                      >
                        {favorite[article.pharmacode] ? (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#e05257]" viewBox="0 0 24 24" fill="currentColor">
                            <path fillRule="evenodd" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#e05257]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78L12 21.23l8.84-8.84a5.5 5.5 0 000-7.78z" />
                          </svg>
                        )}
                      </button>
                     {/* Fixed height container - adjusted for smaller size */}
                     <div className="aspect-[4/3] w-full relative">
                       {/* Image wrapper - adjusted padding */}
                       <div className="absolute inset-0 p-3">
                         {(() => {
                           if (!article.hasPhoto || !article.images || !Array.isArray(article.images.M) || article.images.M.length === 0) {
                             return (
                               <div className="w-full h-full flex items-center justify-center text-gray-500 text-sm">
                                 <span>No Image Available</span>
                               </div>
                             );
                           }

                           const picFront3DImage = article.images.M.find((image) =>
                             image.includes('PICFRONT3D')
                           );
                           const imageToDisplay = picFront3DImage || article.images.M[0];

                           return (
                             <img
                               src={`https://pharma-outlet.com/uploads/${imageToDisplay.replace(/\\/g, '/')}`}
                               alt={article.description?.longDescription || 'Product Image'}
                               className="w-full h-full object-contain"
                             />
                           );
                         })()}
                       </div>
                     </div>

                     {/* Content section - adjusted padding and min-height */}
                     <div className="p-3 flex flex-col min-h-[100px]">
                       {/* Title and description */}
                       <div className="flex-1">
                         <h3 className="text-sm font-medium text-gray-500 line-clamp-2">
                           {article.description?.description || 'Bschrieb nid verfüegbar'}
                         </h3>
                         <p className="mt-1 text-sm italic text-gray-900 font-medium line-clamp-2">
                           {article.description?.longDescription || 'Detail nid verfüegbar'}
                         </p>
                       </div>
                       
                       {/* Price */}
                       <div className="mt-2 pt-2 border-t border-gray-100">
                         <p className="text-sm font-medium text-gray-900">
                           {article.prices ? `${article.prices} CHF` : 'Pris nid verfüegbar'}
                         </p>
                       </div>
                     </div>
                   </div>
                  ))
                )
              ) : (
                <div className="text-center col-span-full">
                  <h3 className="text-lg font-medium text-gray-900">No products found.</h3>
                  <p className="text-gray-500">Try a different search term.</p>
                </div>
              )}
            </div>
          </div>
        </section>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </main>
    </div>
  );
}
