'use client';
import React, { useEffect, useRef, useMemo, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const BestsellerCard = React.memo(({ product, isActive }) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div
      data-id={product.id}
      className={`bestseller-card flex-none w-[90%] sm:w-[80%] md:w-72 bg-white shadow-md rounded-lg p-4 transition-transform duration-300 relative text-center snap-center ${
        isActive ? "scale-105" : "scale-100"
      }`}
      role="article"
    >
      <a 
        href={product.link}
        rel="noopener noreferrer"
        aria-label={`View ${product.title}`}
        className="block focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md"
      >
        {!imageError ? (
          <img
            src={product.image}
            alt={product.title}
            className="w-full h-auto max-h-56 mb-4 object-contain rounded-md"
            loading="lazy"
            width="300"
            height="300"
            onError={() => setImageError(true)}
          />
        ) : (
          <div className="w-full h-56 bg-gray-200 flex items-center justify-center mb-4 rounded-md">
            <span className="text-gray-500">Image not available</span>
          </div>
        )}
      </a>
      <h3 className="text-lg font-semibold mb-1">{product.title}</h3>
      <p className="text-gray-800 font-medium mb-2">{product.price}</p>
    </div>
  );
});

BestsellerCard.displayName = 'BestsellerCard';

const Bestsellers = () => {
  const products = useMemo(() => [
    {
      id: 1,
      title: "Propolis Mundspray Fl 20 ml",
      price: "15.95 CHF",
      image: "images/resized_BS1.webp",
      link: "http://pharma-outlet.com/product/5543484",
    },
    {
      id: 2,
      title: "EMS Sinusitis Spray mit Eukalyptusöl 15 ml",
      price: "8.33 CHF",
      image: "images/resized_BS2.webp",
      link: "http://pharma-outlet.com/product/7815203",
    },
    {
      id: 3,
      title: "Avene Sun Sonnencreme getönt SPF50+ Tb 50 ml",
      price: "20.95 CHF",
      image: "images/resized_BS3.webp",
      link: "http://pharma-outlet.com/product/7815203",
    },
    {
      id: 4,
      title: "Clinique All About Eyes 15 ml",
      price: "29.55 CHF",
      image: "images/resized_BS5.webp",
      link: "http://pharma-outlet.com/product/3491725",
    },
    {
      id: 5,
      title: "Avene Sun Mineralische Sonnencreme SPF 50+ 50 ml",
      price: "20.95 CHF",
      image: "images/resized_BS6.webp",
      link: "http://pharma-outlet.com/product/2778554",
    },
    {
      id: 6,
      title: "Voltaren Dolo Emulgel Tb 60 g",
      price: "7.95 CHF",
      image: "images/resized_BS4.webp",
      link: "http://pharma-outlet.com/product/2836719",
    },
  ], []);

  const scrollContainerRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null);
  const [showControls, setShowControls] = useState(false);

  const handleScroll = useCallback((direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollAmount = container.clientWidth * 0.8;
    const newPosition = container.scrollLeft + (direction === 'right' ? scrollAmount : -scrollAmount);
    
    container.scrollTo({
      left: newPosition,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveCard(Number(entry.target.dataset.id));
          }
        });
      },
      {
        root: container,
        threshold: 0.7,
      }
    );

    const cards = container.querySelectorAll(".bestseller-card");
    cards.forEach((card) => observer.observe(card));

    const handleResize = () => {
      setShowControls(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      cards.forEach((card) => observer.unobserve(card));
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="py-12 md:py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="mb-8 md:mb-16 relative"
        >
          <motion.div 
            className="mx-auto max-w-2xl lg:mx-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Bestseller
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Entdecken Sie unsere meistverkauften Produkte - von unseren Kunden getestet und empfohlen
            </p>
          </motion.div>
          <div className="absolute inset-0 -z-10">
            <div className="absolute inset-0 bg-blue-100 rounded-full blur-3xl opacity-20 transform -translate-y-1/2"></div>
          </div>
        </motion.div>

        <div className="relative">
          {showControls && (
            <>
              <button
                onClick={() => handleScroll('left')}
                className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-2 rounded-full shadow-md hover:bg-white transition-colors"
                aria-label="Previous products"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <button
                onClick={() => handleScroll('right')}
                className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-2 rounded-full shadow-md hover:bg-white transition-colors"
                aria-label="Next products"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </>
          )}
          
          <div
            className="relative overflow-x-auto hide-scrollbar snap-x snap-mandatory"
            ref={scrollContainerRef}
            role="region"
            aria-label="Bestseller products carousel"
          >
            <div className="flex space-x-4 pb-4 px-4">
              {products.map((product) => (
                <BestsellerCard
                  key={product.id}
                  product={product}
                  isActive={activeCard === product.id && window.innerWidth < 768}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <style>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        @media (min-width: 768px) {
          .bestseller-card {
            transform: none !important;
          }
        }
      `}</style>
    </section>
  );
};

BestsellerCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Bestsellers;
