'use client';
import React, { useState, lazy } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function LabServices() {
  const [expandedId, setExpandedId] = useState(null);

  const services = [
    {
      id: 1,
      icon: (
        <svg className="w-12 h-12 md:w-14 md:h-14 text-[#51A550]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <path d="M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"/>
        </svg>
      ),
      title: 'Blutanalysen',
      description: 'Umfassende Blutuntersuchungen mit modernster Technologie für präzise und schnelle Ergebnisse.',
      highlight: 'Ergebnisse in 24h',
      features: ['Großes Blutbild', 'Organwerte', 'Vitaminanalyse']
    },
    {
      id: 2,
      icon: (
        <svg className="w-12 h-12 md:w-14 md:h-14 text-[#51A550]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="12" r="9"/>
          <path d="M12 8v4l2.5 2.5"/>
        </svg>
      ),
      title: 'Express Check-up',
      description: 'Schnelle und umfassende Gesundheitschecks für Ihr Wohlbefinden und Ihre Sicherheit.',
      highlight: 'Ohne Termin möglich',
      features: ['Blutdruck', 'Blutzucker', 'BMI-Analyse']
    },
    {
      id: 3,
      icon: (
        <svg className="w-12 h-12 md:w-14 md:h-14 text-[#51A550]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <path d="M22 12h-4l-3 9L9 3l-3 9H2"/>
        </svg>
      ),
      title: 'Stoffwechsel Plus',
      description: 'Detaillierte Stoffwechselanalyse mit individueller Beratung und Therapieempfehlung.',
      highlight: 'Inkl. Ernährungsplan',
      features: ['Hormonstatus', 'Stoffwechsel', 'Mineralien']
    },
    {
      id: 4,
      icon: (
        <svg className="w-12 h-12 md:w-14 md:h-14 text-[#51A550]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <path d="M16 21v-2a4 4 0 00-4-4H6a4 4 0 00-4 4v2"/>
          <circle cx="9" cy="7" r="4"/>
          <path d="M22 21v-2a4 4 0 00-3-3.87"/>
          <path d="M16 3.13a4 4 0 010 7.75"/>
        </svg>
      ),
      title: 'Expertenberatung',
      description: 'Ausführliche Besprechung Ihrer Laborergebnisse mit erfahrenen Fachärzten.',
      highlight: 'Von Fachärzten',
      features: ['Befundbesprechung', 'Therapieplanung', 'Präventionsberatung']
    },
  ];

  return (
    <section className="py-12 md:py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className=" mb-8 md:mb-16 relative"
        >
          <motion.div 
            className="mx-auto max-w-2xl lg:mx-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Labor & Diagnostik
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Modernste Labordiagnostik für Ihre Gesundheit - schnell, präzise und zuverlässig
            </p>
          </motion.div>
          <div className="absolute inset-0 -z-10">
            <div className="absolute inset-0 bg-blue-100 rounded-full blur-3xl opacity-20 transform -translate-y-1/2"></div>
          </div>
        </motion.div>

        {/* Desktop View */}
        <div className="hidden lg:block">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="relative bg-gradient-to-br from-white to-gray-50 rounded-3xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-100 overflow-hidden"
          >
            <div className="absolute top-0 right-0 w-96 h-96 bg-[#51A550]/5 rounded-full -mr-48 -mt-48 blur-3xl"></div>
            <div className="absolute bottom-0 left-0 w-96 h-96 bg-blue-50/10 rounded-full -ml-48 -mb-48 blur-3xl"></div>
            
            <div className="grid grid-cols-4 gap-8 relative">
              {services.map((service, index) => (
                <motion.div
                  key={service.id}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="group relative bg-white/50 backdrop-blur-sm rounded-xl p-6 hover:bg-white/80 transition-all duration-300"
                >
                  <div className="flex flex-col h-full">
                    <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                      {service.icon}
                    </div>
                    
                    <h3 className="text-xl font-bold text-gray-900 mb-3">
                      {service.title}
                    </h3>
                    
                    <p className="text-base text-gray-600 mb-4 flex-1">
                      {service.description}
                    </p>

                    <div className="space-y-2 mb-6">
                      {service.features.map((feature, idx) => (
                        <div key={idx} className="flex items-center text-sm text-gray-600">
                          <svg className="w-4 h-4 text-[#51A550] mr-2 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                          </svg>
                          <span className="leading-tight">{feature}</span>
                        </div>
                      ))}
                    </div>

                    <div className="mt-auto">
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-[#F6F7FF] text-gray-900">
                        {service.highlight}
                      </span>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>

        {/* Mobile View */}
        <div className="lg:hidden space-y-4">
          {services.map((service) => (
            <motion.div
              key={service.id}
              initial={false}
              className="relative bg-white rounded-xl shadow-md overflow-hidden"
            >
              <motion.button
                className="w-full"
                onClick={() => setExpandedId(expandedId === service.id ? null : service.id)}
              >
                <div className="p-4 flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      {service.icon}
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900">
                      {service.title}
                    </h3>
                  </div>
                  <svg
                    className={`w-5 h-5 text-gray-500 transform transition-transform duration-300 ${expandedId === service.id ? 'rotate-180' : ''}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </motion.button>

              <AnimatePresence>
                {expandedId === service.id && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="overflow-hidden border-t border-gray-100"
                  >
                    <div className="p-4 pt-2 space-y-4">
                      <p className="text-gray-600">
                        {service.description}
                      </p>

                      <div className="space-y-2">
                        {service.features.map((feature, idx) => (
                          <div key={idx} className="flex items-center text-sm text-gray-600">
                            <svg className="w-4 h-4 text-[#51A550] mr-2 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                            </svg>
                            <span>{feature}</span>
                          </div>
                        ))}
                      </div>

                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#51A550]/10 text-[#51A550]">
                        {service.highlight}
                      </span>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
