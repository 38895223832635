
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import CategoryResults from "../components/Shop/CategoryResults";


function CategoryResultsPage() {
    return (
      <div>
       <Nav />
       <CategoryResults />
       <Footer />
      </div>
    );
  }
  
  export default CategoryResultsPage;
  