import { useState, useEffect } from 'react';
import { 
  fetchAllProducts, 
  searchProducts, 
  toggleFavoriteProduct, 
  fetchFavorites, 
  fetchGenericProducts 
} from '../../services/apiService';
import { useNavigate, useLocation } from 'react-router-dom';
import CategorySidebar, { useCategories } from './CategorySidebar';

// Image dimensions configuration
const IMAGE_DIMENSIONS = {
  thumbnail: { width: 300, height: 300 },
  medium: { width: 600, height: 600 },
  large: { width: 900, height: 900 }
};

// Optimized image component with loading states
const OptimizedImage = ({ src, alt, className, isPriority = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse" />
      )}
      <picture>
        <img
          src={src}
          alt={alt}
          width={IMAGE_DIMENSIONS.medium.width}
          height={IMAGE_DIMENSIONS.medium.height}
          className={`${className} ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
          loading={isPriority ? "eager" : "lazy"}
          decoding="async"
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false);
            setError(true);
          }}
        />
      </picture>
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <span className="text-gray-500">Image not available</span>
        </div>
      )}
    </div>
  );
};

// Product Card Component
const ProductCard = ({ article, favorite, onFavoriteClick, onProductClick, isPriority }) => {
  return (
    <div
      onClick={() => onProductClick(article.pharmacode)}
      className="group relative border border-gray-200 rounded-lg bg-white shadow-sm hover:shadow-lg transition-shadow duration-200 cursor-pointer"
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          onFavoriteClick(article.pharmacode);
        }}
        className="absolute top-2 right-2 z-10"
      >
        {favorite[article.pharmacode] ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#e05257]" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#e05257]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78L12 21.23l8.84-8.84a5.5 5.5 0 000-7.78z" />
          </svg>
        )}
      </button>

      {/* Fixed height container */}
      <div className="aspect-[4/3] w-full relative">
        {/* Image wrapper */}
        <div className="absolute inset-0 p-3">
          {(() => {
            if (!article.hasPhoto || !article.images || !Array.isArray(article.images.M) || article.images.M.length === 0) {
              return (
                <div className="w-full h-full flex items-center justify-center text-gray-500 text-sm">
                  <span>No Image Available</span>
                </div>
              );
            }

            const picFront3DImage = article.images.M.find(image => image.includes('PICFRONT3D'));
            const imageToDisplay = picFront3DImage || article.images.M[0];
            const imageUrl = `https://pharma-outlet.com/uploads/${imageToDisplay.replace(/\\/g, '/')}`;

            return (
              <OptimizedImage
                src={imageUrl}
                alt={article.description?.longDescription || 'Product Image'}
                className="w-full h-full object-contain"
                isPriority={isPriority}
              />
            );
          })()}
        </div>
      </div>

      {/* Content section */}
      <div className="p-3 flex flex-col min-h-[100px]">
        {/* Title and description */}
        <div className="flex-1">
          <h3 className="text-sm font-medium text-gray-500 line-clamp-2">
            {article.description?.description || 'Bschrieb nid verfüegbar'}
          </h3>
          <p className="mt-1 text-sm italic text-gray-900 font-medium line-clamp-2">
            {article.description?.longDescription || 'Detail nid verfüegbar'}
          </p>
        </div>
        
        {/* Price */}
        <div className="mt-2 pt-2 border-t border-gray-100">
          <p className="text-sm font-medium text-gray-900">
            {article.prices ? (
              `CHF ${Number(article.prices).toLocaleString('de-CH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`
            ) : 'Pris nid verfüegbar'}
          </p>
        </div>
      </div>
    </div>
  );
};


export default function AllProducts({ currentPage, setCurrentPage, setTotalPages }) {
  const [products, setProducts] = useState([]);
  const [favorite, setFavorite] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const genericCode = queryParams.get('genericCode');
  const level = queryParams.get('level');
  const view = queryParams.get('view');


  useEffect(() => {
    const fetchAndDisplayProducts = async () => {
      setLoading(true);
      const query = new URLSearchParams(location.search);
      const searchTerm = query.get('search');
      const genericCode = query.get('genericCode');
      const level = query.get('level');
      const view = query.get('view');
      
      if (searchTerm !== lastSearchTerm) {
        setCurrentPage(1);
        setLastSearchTerm(searchTerm);
      }
      
      try {
        let response;
        
        if (view === 'generic' && genericCode && level) {
          // Handle generic products view
          response = await fetchGenericProducts(genericCode, level, currentPage, 20);
          if (response?.success) {
            setProducts(response.data.products || []);
            setTotalPages(response.data.totalPages || 1);
          }
        } else if (searchTerm) {
          // Handle search results
          response = await searchProducts(searchTerm, currentPage);
          if (response?.success && response.data) {
            setProducts(response.data.products || []);
            setTotalPages(response.data.totalPages || 1);
          }
        } else {
          // Handle all products
          response = await fetchAllProducts(currentPage, 20);
          if (response?.success && response.data) {
            setProducts(response.data.products || []);
            setTotalPages(response.data.totalPages || 1);
          }
        }

        if (!response?.success) {
          console.error('Invalid response structure:', response);
          setProducts([]);
          setTotalPages(1);
        }
      } catch (error) {
        console.error('Failed to fetch products:', error);
        setProducts([]);
        setTotalPages(1);
      } finally {
        setLoading(false);
      }
    };

    fetchAndDisplayProducts();
  }, [location.search, currentPage, lastSearchTerm]);



  const handleViewProduct = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
  };


  const handleFavoriteClick = async (pharmacode) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/loginpage');
      return;
    }


    try {
      await toggleFavoriteProduct(pharmacode);
      setFavorite((prev) => ({
        ...prev,
        [pharmacode]: !prev[pharmacode],
      }));
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };


  return (
    <div className="bg-white">
      <div>
        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-10">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                {view === 'generic' 
                  ? 'Generische Produkte'
                  : location.search 
                    ? 'Search Results' 
                    : 'All Products'}
              </h1>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-4 gap-x-8">

          <CategorySidebar />
  
            <div className="lg:col-span-3">
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
                {loading ? (
                  <div className="flex justify-center items-center h-64 col-span-full">
                <svg
                  className="animate-spin h-10 w-10 text-[#3A7861]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
      <span className="ml-2 text-gray-500">Loading products...</span>
    </div>
  ) : products && products.length > 0 ? (
    products.flatMap((product) => {
      if (!product || !product._id) return [];

      // Handle products with nested articles (generic products)
      if (product.articles && Array.isArray(product.articles)) {
        return product.articles.map(article => {
          const processedArticle = {
            pharmacode: article.pharmacode,
            description: {
              description: article.description?.description || 'No description available',
              longDescription: article.description?.longDescription || 'No description available'
            },
            hasPhoto: article.hasPhoto || false,
            images: {
              M: Array.isArray(article.images?.M) ? article.images.M.map(img => img.replace(/^uploads\\/, '')) : []
            },
            prices: article.prices || null
          };

          return (
            <ProductCard
              key={article.pharmacode}
              article={processedArticle}
              favorite={favorite}
              onFavoriteClick={handleFavoriteClick}
              onProductClick={handleViewProduct}
              isPriority={false}
            />
          );
        });
      }

      // Handle regular products
      const article = {
        pharmacode: product._id,
        description: {
          description: product.description?.description || 'No description available',
          longDescription: product.description?.longDescription || product.longDescription || 'No description available'
        },
        hasPhoto: product.hasPhoto || false,
        images: {
          M: Array.isArray(product.images) ? product.images.map(img => img.replace(/^uploads\\/, '')) : []
        },
        prices: product.prices?.price || null
      };

      return (
        <ProductCard
          key={product._id}
          article={article}
          favorite={favorite}
          onFavoriteClick={handleFavoriteClick}
          onProductClick={handleViewProduct}
          isPriority={false}
        />
      );
    })
  ) : (
    <div className="text-center col-span-full">
      <h3 className="text-lg font-medium text-gray-900">No products found.</h3>
      <p className="text-gray-500">Try a different search term.</p>
    </div>
  )}
</div>
</div>
          </section>
        </main>
      </div>
    </div>
  );
}
