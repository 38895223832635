export default function WhyGetTested() {
  const benefits = [
    {
      title: 'Früherkennung',
      description: 'Regelmäßige Laboruntersuchungen ermöglichen die frühzeitige Erkennung von Gesundheitsrisiken, bevor Symptome auftreten.',
      icon: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
          <path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm1-13h-2v6h6v-2h-4V7z" />
        </svg>
      ),
    },
    {
      title: 'Präzise Diagnose',
      description: 'Unsere modernen Laboranalysen liefern exakte Daten für eine genaue Diagnose und optimale Behandlungsplanung.',
      icon: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
          <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V6h16v12zM6 10h2v2H6zm0 4h8v2H6z" />
        </svg>
      ),
    },
    {
      title: 'Präventive Gesundheitsvorsorge',
      description: 'Regelmäßige Check-ups helfen dabei, Ihre Gesundheit proaktiv zu managen und Risikofaktoren zu minimieren.',
      icon: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
          <path d="M12 2L4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25 6 2.25v4.7z" />
        </svg>
      ),
    },
  ]

  return (
    <div className="relative isolate overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-24 sm:py-32">
        {/* Header section */}
        <div className="max-w-2xl lg:max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-blue-600">
            Gesundheitsvorsorge
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Warum regelmäßige Laboruntersuchungen wichtig sind
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Laboruntersuchungen sind ein essentieller Bestandteil der modernen Medizin und Gesundheitsvorsorge. Sie liefern wichtige Informationen über Ihren Gesundheitszustand und ermöglichen eine frühzeitige Intervention bei gesundheitlichen Problemen.
          </p>
        </div>


        {/* Cards section */}
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:max-w-none lg:grid-cols-3">
          {benefits.map((benefit) => (
            <div
              key={benefit.title}
              className="flex flex-col bg-white/80 backdrop-blur-xl rounded-3xl px-6 py-8 shadow-lg ring-1 ring-gray-900/10 hover:bg-white/95 transition-all duration-300 hover:-translate-y-1"
            >
              <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-r from-blue-600 to-indigo-600 shadow-md">
                <benefit.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="mt-6 text-lg font-semibold leading-7 tracking-tight text-gray-900">
                {benefit.title}
              </h3>
              <p className="mt-2 text-base leading-7 text-gray-600">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
