import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchProducts } from '../services/apiService';
import { motion, AnimatePresence } from 'framer-motion';

const SearchBarCompact = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [previewResults, setPreviewResults] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    setShowPreview(false);
    setSearchTerm('');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm.trim().length >= 2) {
      setIsLoading(true);
      setShowPreview(true);

      const fetchResults = async () => {
        try {
          const response = await searchProducts(searchTerm, 1, 5);
          console.log('Search API Response:', response);
          if (response?.success && Array.isArray(response.data.products)) {
            console.log('Products:', response.data.products);
            setPreviewResults(response.data.products);
          }
        } catch (error) {
          console.error('Search preview failed:', error);
          setPreviewResults([]);
        } finally {
          setIsLoading(false);
        }
      };

      const timer = setTimeout(fetchResults, 150);
      return () => clearTimeout(timer);
    } else {
      setPreviewResults([]);
      setShowPreview(false);
    }
  }, [searchTerm]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/products?search=${encodeURIComponent(searchTerm)}`);
      handleClose();
    }
  };

  const handlePreviewClick = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
    handleClose();
  };

  const getOptimizedImageUrl = (images) => {
    if (!Array.isArray(images) || images.length === 0) return null;
    
    const picFront3D = images.find(image => image.includes('PICFRONT3D'));
    if (picFront3D) {
      return picFront3D;
    }
    
    const picFront = images.find(image => image.includes('PICFRONT'));
    if (picFront) {
      return picFront;
    }
    
    return images[0];
  };

  return (
    <>
      <button
        className="p-2.5 rounded-full hover:bg-gray-100 transition-all duration-200 ease-in-out"
        onClick={() => setIsOpen(true)}
        aria-label="Open search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-gray-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-4.35-4.35M16.1 10.95a5.15 5.15 0 11-10.3 0 5.15 5.15 0 0110.3 0z"
          />
        </svg>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 z-50 bg-black/50 backdrop-blur-sm"
            onClick={handleClose}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0, y: -20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0.95, opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="min-h-screen px-4 pt-20 text-center overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <div ref={searchRef} className="inline-block w-full max-w-2xl text-left align-middle transition-all transform">
                <div className="relative bg-white rounded-2xl shadow-2xl p-6">
                  <button
                    onClick={handleClose}
                    className="absolute right-4 top-4 p-2 rounded-full hover:bg-gray-100 transition-all duration-200 ease-in-out"
                    aria-label="Close search"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-gray-500 hover:text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>

                  <form onSubmit={handleSearchSubmit} className="relative">
                    <div className="relative">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full px-5 py-4 pr-12 text-lg border-2 border-gray-200 rounded-xl focus:border-gray-100 focus:ring-2 focus:ring-gray-100 transition-all duration-200 placeholder-gray-400"
                        placeholder="Search products..."
                        autoFocus
                      />
                      <button
                        type="submit"
                        className="absolute right-3 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-gray-600"
                        aria-label="Submit search"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-8 w-8"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-4.35-4.35M16.1 10.95a5.15 5.15 0 11-10.3 0 5.15 5.15 0 0110.3 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>

                  <AnimatePresence>
                    {showPreview && searchTerm.trim().length >= 2 && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="mt-6 divide-y divide-gray-100"
                      >
                        {isLoading ? (
                          <div className="py-8 text-center">
                            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-green-500 border-r-transparent"></div>
                          </div>
                        ) : previewResults.length > 0 ? (
                          <motion.div
                            className="space-y-2"
                            initial="hidden"
                            animate="visible"
                            variants={{
                              visible: {
                                transition: {
                                  staggerChildren: 0.05
                                }
                              }
                            }}
                          >
                            {previewResults.map((product) => {
                              console.log('Price debug:', {
                                directPrice: product.prices?.price,
                                articlePrice: product.articles?.[0]?.prices?.price,
                                fullPricesObject: product.prices,
                                fullArticlesObject: product.articles
                              });
                              const article = {
                                pharmacode: product._id,
                                description: {
                                  description: product.description?.description || 'No description available',
                                  longDescription: product.description?.longDescription || product.longDescription || 'No description available'
                                },
                                hasPhoto: product.hasPhoto || false,
                                images: {
                                  M: Array.isArray(product.images) ? product.images.map(img => img.replace(/^uploads\\/, '')) : []
                                },
                                prices: product.prices?.price || 
                                       (product.articles?.[0]?.prices?.price) || 
                                       null
                              };
                              console.log('Processed article:', article);

                              return (
                                <motion.div
                                  key={product._id}
                                  variants={{
                                    hidden: { opacity: 0, y: 10 },
                                    visible: { opacity: 1, y: 0 }
                                  }}
                                  whileHover={{ scale: 1.02 }}
                                  onClick={() => handlePreviewClick(product._id)}
                                  className="p-4 rounded-xl hover:bg-gray-50 cursor-pointer transition-all duration-200 ease-in-out"
                                >
                                  <div className="flex items-center space-x-4">
                                    <div className="w-16 h-16 flex-shrink-0 rounded-lg overflow-hidden">
                                      {article.hasPhoto && article.images.M.length > 0 ? (
                                        <img
                                          src={`https://pharma-outlet.com/uploads/${getOptimizedImageUrl(article.images.M).replace(/\\/g, '/')}`}
                                          alt={article.description.description}
                                          className="w-full h-full object-contain"
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/images/placeholder.jpg';
                                          }}
                                        />
                                      ) : (
                                        <div className="w-full h-full flex items-center justify-center">
                                          <svg className="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                          </svg>
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 line-clamp-2">
                                        {article.description.longDescription}
                                      </p>
                                      <p className="mt-1 text-sm font-semibold text-green-600">
                                        {article.prices ? `CHF ${article.prices}` : 'Preis nicht verfügbar'}
                                      </p>
                                    </div>
                                  </div>
                                </motion.div>
                              );
                            })}
                          </motion.div>
                        ) : (
                          <div className="py-8 text-center text-gray-500">
                            Keine Ergebnisse gefunden für "{searchTerm}"
                          </div>
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SearchBarCompact;