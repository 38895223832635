// src/services/apiServices.js
import APIClient from './apiClient';
import axios from 'axios';

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in localStorage');
    return null;
  }
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    const payload = JSON.parse(jsonPayload); // Decode JWT token
    return payload.id; // Assuming the token payload contains the user ID as `id`
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const signup = async (userData) => {
  try {
    const response = await APIClient.post(`/authentication/signup`, userData);
    return response.data;
  } catch (error) {
    console.error('Error during signup:', error);
    throw error;
  }
};

export const login = async (credentials) => {
  try {
    const response = await APIClient.post(`/authentication/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

export const loginUser = async (credentials) => {
  try {
    const response = await APIClient.post(`/authentication/user/login`, credentials);
    const token = response.data.data.token; // Ensure you are accessing the token correctly
    if (token) {
      localStorage.setItem('token', token); // Store the token in localStorage
    } else {
      console.error('Token not found in response');
    }
    return response.data;
  } catch (error) {
    console.error('Error during user login:', error);
    throw error;
  }
};

export const getProfile = async () => {
  const userId = getUserIdFromToken();
  if (!userId) {
    throw new Error('User ID not found in token');
  }

  try {
    const response = await APIClient.get(`/authentication/${userId}/profile`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const logoutUser = () => {
  localStorage.removeItem('token');
};

export const deleteAccount = async () => {
  try {
    const response = await APIClient.post(`/authentication/delete-account`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during account deletion:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const editProfile = async (profileData) => {
  try {
    const response = await APIClient.put(`/authentication/edit-profile`, profileData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error editing profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchAllProducts = async (page = 1, limit = 20) => {
  try {
    // Pass page and limit as query params
    const response = await APIClient.get(`/getAllProducts`, {
      params: { page, limit }, // Sending page and limit as query parameters
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};


export const fetchProductDetails = async (pharmacode) => {
  try {
    const response = await APIClient.get(`/${pharmacode}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product details:', error);
    throw error;
  }
};

export const toggleFavoriteProduct = async (pharmacode) => {
  try {
    const response = await APIClient.post(`/favorites/${pharmacode}/toggle`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error toggling favorite:', error);
    throw error;
  }
};

// Add: Fetch user's favorite products
export const fetchFavorites = async () => {
  try {
    const response = await APIClient.get(`/getAllFavorites`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching favorites:', error);
    throw error;
  }
};

// In apiService.js
export const searchProducts = async (searchTerm, page = 1, limit = 20) => {
  try {
    const response = await APIClient.get('/search', {
      params: {
        searchTerms: searchTerm,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching products:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const requestPasswordReset = async (email) => {
  try {
    const response = await APIClient.post('/authentication/forgetPassword', { email });
    return response.data;
  } catch (error) {
    console.error('Error requesting password reset:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const resetPassword = async (id, token, newPassword, confirmNewPassword) => {
  try {
    // Pass the id and token as query parameters, and the passwords in the body
    const response = await APIClient.post(`/authentication/reset-password`, {
      newPassword,
      confirmNewPassword,
    }, {
      params: {
        id,
        token,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const requestLoggedInPasswordReset = async () => {
  try {
    const response = await APIClient.post('/authentication/reset', {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include authorization token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error requesting logged-in password reset:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchProductsBySubcategory = async (subcategoryId, page = 1, limit = 20) => {
  try {
    // Don't encode the category name, just ensure proper spacing
    const formattedCategory = subcategoryId.replace(/\s+/g, ' ').trim();
    
    const response = await APIClient.get(`/getProductsByCategory`, {
      params: { 
        consumerCategoryId: formattedCategory,
        page, 
        limit 
      }
    });
    
    if (!response.data.success) {
      throw new Error(response.data.error || 'Failed to fetch products');
    }
    
    return response.data;
  } catch (error) {
    console.error('Full error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      requestUrl: error.config?.url,
      requestParams: error.config?.params
    });
    throw error;
  }
};


export const fetchCategories = async () => {
  try {
    const response = await APIClient.get(`/getCategories`);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const fetchProductsByGenericCode = async (genericCode, page = 1, limit = 20) => {
  try {
    const response = await APIClient.get(`/getProductsByGenericCodes`, {
      params: { genericCode, page, limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products by generic code:', error);
    throw error;
  }
};

// Get all items in cart
export const getAllCartItems = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return { success: true, data: { cart: [] } };
    }

    const response = await APIClient.get('/cart/getAllItems', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    // If we get a successful response
    return {
      success: true,
      data: {
        cart: response.data?.data?.cart || []
      }
    };

  } catch (error) {
    // Handle empty cart case (current backend implementation)
    if (error.response?.status === 400) {
      return {
        success: true,
        data: {
          cart: []
        }
      };
    }

    // Handle unauthorized
    if (error.response?.status === 401) {
      throw error;
    }

    // Log other errors
    console.error('Cart fetch error:', error);
    return {
      success: true,
      data: {
        cart: []
      }
    };
  }
};
// Update other cart-related functions to match backend routes
export const addToCart = async (pharmacode, quantity = 1) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await APIClient.post(`/cart/${pharmacode}/addToCart`, 
      { quantity },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Add to cart error:', error);
    throw error;
  }
};

export const removeFromCart = async (productId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await APIClient.post(`/cart/${productId}/removeFromCart`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Remove from cart error:', error);
    throw error;
  }
};
// Update cart item quantity
export const updateCartQuantity = async (pharmacode, quantity) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await APIClient.post(`/cart/${pharmacode}/addToCart`,
      { quantity: quantity },  // Send the absolute quantity, not the difference
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    
    console.log('Update cart quantity response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Update cart quantity error:', error);
    throw error;
  }
};
// Clear entire cart
export const clearCart = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await APIClient.post('/cart/clear',
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    
    console.log('Clear cart response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Clear cart error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    throw error;
  }
};

// Get cart count
export const getCartCount = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await APIClient.get('/cart/count', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    
    console.log('Cart count response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Get cart count error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    throw error;
  }
};

export const fetchGenericProducts = async (genericCode, level, page = 1, limit = 20) => {
  try {
    const response = await APIClient.get(`/getSimilarProductsByGenericCode`, {
      params: {
        genericCode,
        level,
        page,
        limit
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching generic products:', error);
    throw error;
  }
};