import { useState, useEffect, useRef } from 'react'; 
import { useParams } from 'react-router-dom';
import { fetchProductDetails, fetchProductsByGenericCode, updateCartQuantity } from '../../services/apiService';
import { Disclosure } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductDetails() {
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image
  const [isZoomed, setIsZoomed] = useState(false); // Track zoom state
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 }); // Track mouse position for zoom
  const { pharmacode } = useParams();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [showRelatedProducts, setShowRelatedProducts] = useState(false);
  const relatedProductsRef = useRef(null); // Reference for scrolling
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false); // New state
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isProductAdded, setIsProductAdded] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [cartError, setCartError] = useState(null);
  

  useEffect(() => {
    if (showRelatedProducts && buttonClicked) {
      relatedProductsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setButtonClicked(false); // Reset the flag
    }
  }, [showRelatedProducts, buttonClicked]);


  
  const handleGenerateSimilarProducts = async () => {
    setLoading(true); // Start loading spinner
    try {
      const genericCode = product.articles[0]?.genericGroupCodes?.[0];
      if (!genericCode) {
        console.error('No generic code found for this product.');
        setLoading(false);
        return;
      }

      const response = await fetchProductsByGenericCode(genericCode);
      const fetchedProducts = response.data.products;

      if (fetchedProducts.length > 0) {
        setRelatedProducts(fetchedProducts);
        setShowRelatedProducts(true);
      } else {
        console.warn('No related products found.');
        setShowRelatedProducts(false);
      }
    } catch (error) {
      console.error('Error fetching related products:', error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };
  
  
  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const response = await fetchProductDetails(pharmacode);
        setProduct(response.data.product);
  
        // Find the article with the given pharmacode
        const articleWithPhotos = response.data.product.articles.find(
          (article) => article.pharmacode === parseInt(pharmacode)
        );
  
        if (articleWithPhotos && articleWithPhotos.hasPhoto && articleWithPhotos.images?.F?.length > 0) {
          // Reorder images: PICFRONT3D first, then PICBACK3D, then other images
          const reorderedImages = reorderImages(articleWithPhotos.images.F);
  
          // Set the initial selected image (prioritize PICFRONT3D)
          setSelectedImage(`https://pharma-outlet.com/uploads/${reorderedImages[0].replace(/\\/g, '/')}`);
        } else {
          console.warn('No images available for this article.');
        }
      } catch (error) {
        console.error('Failed to fetch product details:', error);
      }
    };
  
    getProductDetails();
  }, [pharmacode]);
  
  

  // Reorder images to prioritize PICFRONT3D, PICBACK3D, then other images
  const reorderImages = (images) => {
    const picFront3D = images.find((image) => image.includes('PICFRONT3D'));
    const picBack3D = images.find((image) => image.includes('PICBACK3D'));
    const otherImages = images.filter(
      (image) => !image.includes('PICFRONT3D') && !image.includes('PICBACK3D')
    );

    const orderedImages = [];
    if (picFront3D) orderedImages.push(picFront3D);
    if (picBack3D) orderedImages.push(picBack3D);
    return [...orderedImages, ...otherImages];
  };

  const handleAddToCart = async (e) => {
    e.preventDefault();
    
    // Check for token first
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/loginpage');
      return;
    }
  
    try {
      setAddingToCart(true);
      setCartError(null);
      const response = await updateCartQuantity(pharmacode, quantity);
      if (response.success) {
        setIsProductAdded(true);
        setTimeout(() => setIsProductAdded(false), 2000);
      } else {
        setCartError('Failed to add item to cart');
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
      // Only set error if it's not an auth error
      if (error.response?.status !== 401) {
        setCartError(error.response?.data?.error || 'Failed to add item to cart');
      }
    } finally {
      setAddingToCart(false);
    }
  };

  if (!product) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  // Find the article with the current pharmacode
  const articleWithPhotos = product.articles.find(
    (article) => article.pharmacode === parseInt(pharmacode)
  );

  if (!articleWithPhotos) {
    return <div className="flex items-center justify-center h-screen">No article found for this pharmacode</div>;
  }

  const images = articleWithPhotos.images?.F || []; // Get all F folder images
  const reorderedImages = reorderImages(images); // Reorder images for gallery display

  const consumerFolder = articleWithPhotos.consumerFolder?.[0] || {};
  const summary = consumerFolder.summary || 'No summary available';

  // Fetch the regulation code
  const regulationCode = articleWithPhotos?.regulations?.[0]?.code?.id || null;

  // Handle mouse movement over the image to set zoom position
  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-12">
          {/* Image gallery */}
          <div className="flex flex-col-reverse">
            {reorderedImages.length > 0 ? (
              <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                <div className="grid grid-cols-4 gap-4">
                  {reorderedImages.map((image, index) => (
                    <div
                      key={index}
                      className="group relative flex h-24 w-full cursor-pointer items-center justify-center rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow"
                      onClick={() => setSelectedImage(`https://pharma-outlet.com/uploads/${image.replace(/\\/g, '/')}`)}
                    >
                      <span className="sr-only">{index + 1}</span>
                      <span className="absolute inset-0 overflow-hidden rounded-lg">
                        <img
                          alt={`Product ${index + 1}`}
                          src={`https://pharma-outlet.com/uploads/${image.replace(/\\/g, '/')}`}
                          className="h-full w-full object-contain object-center"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="text-center text-gray-500">No images available</div>
            )}

            {/* Main image with zoom functionality */}
            {selectedImage && (
              <div
                className="relative aspect-w-1 aspect-h-1 w-full rounded-lg overflow-hidden shadow-lg bg-white"
                onMouseMove={isZoomed ? handleMouseMove : null}
                onMouseEnter={() => setIsZoomed(true)}
                onMouseLeave={() => setIsZoomed(false)}
              >
              <img
                alt={articleWithPhotos.description?.longDescription || 'Description not available'}
                src={selectedImage}
                className={classNames(
                  'h-full w-full object-contain object-center transition-transform duration-300',
                  isZoomed ? 'scale-150' : 'scale-100'
                )}
                style={isZoomed ? { transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%` } : {}}
              />
              </div>
            )}
          </div>

          {/* Product info */}
          <div className="mt-10 lg:mt-0">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900">
              {articleWithPhotos.description?.longDescription || 'No description available'}
            </h1>
            <div className="mt-4 text-2xl">
            <div className="mt-2">
              <p className="text-lg text-gray-700 leading-relaxed">
                {articleWithPhotos.description?.description || 'No additional information available'}
              </p>
            </div>
              {regulationCode === 'Sale.Web.Rx' ? (
                <div className="mt-4 text-lg text-[#E05257]">Rezeptpflichtig</div>
              ) : (
                articleWithPhotos.prices ? (
                  <div>
                    <div>
                      CHF {Number(articleWithPhotos.prices).toLocaleString('en-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </div>
                    <div className="text-sm text-gray-500 mt-1">inkl. MwSt.</div>
                  </div>
                ) : (
                  <div className="text-lg mt-5">Pris nid verfüegbar</div>
                )
              )}
            </div>


        {/* Container to hold both buttons side by side */}
          {/* Regulations section */}
            {regulationCode && regulationCode !== 'Sale.Web.OK' && (
              <div className="rounded-md bg-yellow-50 p-4 mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M18 16a2 2 0 01-1.732 1H3.732A2 2 0 012 16l7-12a2 2 0 013.464 0l7 12zm-9-2h2v2H9v-2zm0-4h2v2H9v-2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Wichtiger Hinweis</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      {regulationCode === 'Sale.Web.CC' && (
                        <p>Wir dürfen diesen Artikel nur in Apotheken verkaufen. Keine Versandkosten.</p>
                      )}
                      {regulationCode === 'Sale.Web.Rx' && (
                        <p>Wir dürfen diesen Artikel nur auf Rezept abgeben.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Features section */}
          <section className="mt-6">
            <h2 className="text-lg font-medium text-gray-900">Information</h2>
            <div className="mt-4 border-t border-gray-200 pt-2">
              {/* Summary Section */}
              {consumerFolder?.summary && (
                <div className="mb-3">
                  <div className="text-sm text-gray-600 prose prose-sm max-w-none">
                    <div className="relative">
                      <div 
                        dangerouslySetInnerHTML={{ __html: consumerFolder.summary }}
                        className="line-clamp-3 overflow-hidden"
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: '3',
                          WebkitBoxOrient: 'vertical'
                        }}
                      />
                      <button
                        onClick={() => {
                          const zusammenfassungSection = document.querySelector('[data-section="zusammenfassung"]');
                          if (zusammenfassungSection) {
                            zusammenfassungSection.scrollIntoView({ behavior: 'smooth' });
                          }
                        }}
                        className="text-[#568774] hover:text-[#3A7861] font-medium cursor-pointer"
                      >
                        Mehr anzeigen
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Product Details */}
              <ul className="space-y-2 text-sm text-gray-600">
                <li>Artikelnummer: {articleWithPhotos.pharmacode || 'nicht definiert'}</li>
                {articleWithPhotos.prices?.[0]?.company?.description && (
                  <li>Hersteller: {articleWithPhotos.prices[0].company.description}</li>
                )}
              </ul>
            </div>
          </section>
            <div className="mt-6 space-y-6">
            {/* Add to Cart form */}
            {regulationCode !== 'Sale.Web.Rx' && articleWithPhotos.prices && (
              <form className="flex-1" onSubmit={handleAddToCart}>
                {/* Quantity and Price Calculator */}
                <div className="bg-gray-50 p-4 rounded-lg mb-4">
                  <div className="flex flex-col space-y-4">
                    {/* Quantity and Price Row */}
                    <div className="flex flex-row items-center justify-between">
                      {/* Quantity Selector */}
                      <div className="flex items-center space-x-2">
                        <label htmlFor="quantity" className="text-sm font-medium text-gray-700 whitespace-nowrap">
                          Menge:
                        </label>
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => setQuantity(Math.max(1, quantity - 1))}
                            className="rounded-l-md border border-gray-300 px-3 py-1 text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3A7861]"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            id="quantity"
                            value={quantity}
                            min="1"
                            onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                            // Add this CSS class to the input element:
                            className="w-14 border-y border-gray-300 py-1 text-center focus:outline-none focus:ring-2 focus:ring-[#3A7861] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                          />
                          <button
                            type="button"
                            onClick={() => setQuantity(quantity + 1)}
                            className="rounded-r-md border border-gray-300 px-3 py-1 text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#3A7861]"
                          >
                            +
                          </button>
                        </div>
                      </div>

                      {/* Total Price Display */}
                      <div className="flex flex-col items-end">
                        <span className="text-sm text-gray-500">Gesamtpreis:</span>
                        <span className="text-lg font-medium text-gray-900">
                          CHF {(Number(articleWithPhotos.prices) * quantity).toLocaleString('en-CH', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                  <button
                    type="submit"
                    disabled={addingToCart}
                    className="flex-1 flex items-center justify-center rounded-full shadow-lg bg-[#568774] px-8 py-3 text-base font-medium text-white hover:bg-[#5CA566] transition-colors focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {addingToCart ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                        </svg>
                        Adding...
                      </span>
                    ) : isProductAdded ? (
                      'Produkt wurde in den Warenkorb gelegt!'
                    ) : (
                      'In den Warenkorb'
                    )}
                  </button>

                  {product?.articles[0]?.genericGroupCodes?.[0] && (
                    <button
                      type="button"
                      onClick={() => {
                        setButtonClicked(true);
                        handleGenerateSimilarProducts();
                      }}
                      className="flex-1 flex items-center justify-center shadow-lg rounded-full bg-gray-100 px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-200 relative disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                          ></path>
                        </svg>
                      )}
                      Generische Produkte finden
                    </button>
                  )}
                </div>

                {cartError && (
                  <p className="mt-2 text-sm text-red-600">{cartError}</p>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
        
        <section className="bg-white mt-12 w-full mx-auto">
        <div className="max-w-8xl mx-auto text-left">
          <dl className="space-y-6">
            {/* Summary Disclosure */}
            {summary && (
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold" data-section="zusammenfassung">
                      <span>Zusammenfassung</span>
                      <span className="flex items-center">
                        {open ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel as="p" className="mt-2 pr-12 text-gray-600 text-sm">
                      <div dangerouslySetInnerHTML={{ __html: summary }} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}

            {/* Nutritions Section */}
            {articleWithPhotos.nonPharmaInformation?.[0]?.nutritions?.length > 0 && (
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold border-t border-gray-200 pt-8">
                      <span>Nährwertangaben</span>
                      <span className="flex items-center">
                        {open ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-4">
                      {/* Mobile view */}
                      <div className="block sm:hidden">
                        <div className="text-xs font-medium text-gray-500 uppercase mb-4">
                          Per {articleWithPhotos.nonPharmaInformation[0].nutritions[0].referenceQuantity} {articleWithPhotos.nonPharmaInformation[0].nutritions[0].referenceUnitCode.description}
                        </div>
                        {articleWithPhotos.nonPharmaInformation[0].nutritions
                          .sort((a, b) => a.lineNumber - b.lineNumber)
                          .map((nutrition) => (
                            <div key={nutrition.lineNumber} className="border-b border-gray-200 py-4">
                              <div className="text-sm text-gray-900 mb-1">
                                {nutrition.nutritionCode.description}
                              </div>
                              <div className="text-sm font-medium text-gray-900">
                                {nutrition.quantity} {nutrition.unitCode.description}
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* Desktop view */}
                      <div className="hidden sm:block overflow-x-auto pr-12">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Nährstoff
                              </th>
                              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Pro {articleWithPhotos.nonPharmaInformation[0].nutritions[0].referenceQuantity} {articleWithPhotos.nonPharmaInformation[0].nutritions[0].referenceUnitCode.description}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {articleWithPhotos.nonPharmaInformation[0].nutritions
                              .sort((a, b) => a.lineNumber - b.lineNumber)
                              .map((nutrition) => (
                                <tr key={nutrition.lineNumber}>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {nutrition.nutritionCode.description}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                    {nutrition.quantity} {nutrition.unitCode.description}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}  

              {/* Usage Section */}
              {articleWithPhotos.nonPharmaInformation?.[0]?.usageHtml && (
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold border-t border-gray-200 pt-8">
                        <span>Anwendung</span>
                        <span className="flex items-center">
                          {open ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel as="p" className="mt-2 pr-12 text-gray-600 text-sm">
                        <div dangerouslySetInnerHTML={{ __html: articleWithPhotos.nonPharmaInformation[0].usageHtml }} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}

              {/* Characteristics Section */}
              {articleWithPhotos.nonPharmaInformation?.[0]?.characteristicsHtml && (
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold border-t border-gray-200 pt-8">
                        <span>Eigenschaften</span>
                        <span className="flex items-center">
                          {open ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel as="p" className="mt-2 pr-12 text-gray-600 text-sm">
                        <div dangerouslySetInnerHTML={{ __html: articleWithPhotos.nonPharmaInformation[0].characteristicsHtml }} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}

          {/* Information Section */}
            {articleWithPhotos.nonPharmaInformation?.[0]?.informationHtml && (
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold border-t border-gray-200 pt-8">
                      <span>Wichtige Hinweise</span>
                      <span className="flex items-center">
                        {open ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel as="p" className="mt-2 pr-12 text-gray-600 text-sm">
                      <div dangerouslySetInnerHTML={{ __html: articleWithPhotos.nonPharmaInformation[0].informationHtml }} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}

             {/* Dietary Advice Section */}
              {articleWithPhotos.nonPharmaInformation?.[0]?.dietaryAdvice?.length > 0 && (
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold border-t border-gray-200 pt-8">
                        <span>Ernährungshinweise</span>
                        <span className="flex items-center">
                          {open ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                        </Disclosure.Button>
                        <Disclosure.Panel as="div" className="mt-2 pr-12 text-gray-600">
                          <ul className="list-disc pl-5 space-y-2">
                            {articleWithPhotos.nonPharmaInformation[0].dietaryAdvice.map((item, index) => (
                              <li key={item.dietType.id} className="text-sm">
                                {item.dietType.description}
                              </li>
                            ))}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}

                {/* Composition Section */}
                {articleWithPhotos.nonPharmaInformation?.[0]?.compositions?.length > 0 && (
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold border-t border-gray-200 pt-8">
                          <span>Zusammensetzung</span>
                          <span className="flex items-center">
                            {open ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                          </span>
                        </Disclosure.Button>
                        <Disclosure.Panel as="div" className="mt-2 pr-12 text-gray-600 text-sm">
                          {articleWithPhotos.nonPharmaInformation[0].compositions
                            .sort((a, b) => a.lineNumber - b.lineNumber)
                            .map((composition) => (
                              <div 
                                key={composition.lineNumber}
                                dangerouslySetInnerHTML={{ __html: composition.description }}
                                className="prose prose-sm max-w-none"
                              />
                            ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}

              {/* Patient Information Section */}
{articleWithPhotos?.monographies?.length > 0 && 
 articleWithPhotos.monographies[0]?.data?.bodySections?.length > 0 && (
  <>
    {/* Main Patient Info Section */}
    <div className="border-t border-gray-200 pt-8">
      <h2 className="text-gray-900 text-xl font-semibold">Patienteninformation</h2>
      <div className="mt-2 pr-12 text-gray-600 text-sm">
        {articleWithPhotos.monographies[0]?.data?.header && (
          <div 
            dangerouslySetInnerHTML={{ __html: articleWithPhotos.monographies[0].data.header }} 
            className="prose prose-sm max-w-none" 
          />
        )}
        {articleWithPhotos.monographies[0]?.data?.title && (
          <h4 
            className="font-medium mt-2" 
            dangerouslySetInnerHTML={{ __html: articleWithPhotos.monographies[0].data.title }} 
          />
        )}
        {articleWithPhotos.monographies[0]?.data?.authorizationOwnerName && (
          <p className="text-sm text-gray-600 mt-1">
            {articleWithPhotos.monographies[0].data.authorizationOwnerName}
          </p>
        )}
      </div>
    </div>

    {/* Individual Sections */}
    {articleWithPhotos.monographies[0]?.data?.bodySections?.map((section) => (
      <Disclosure as="div" key={section.sectionNumber}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between items-center text-gray-900 font-semibold border-t border-gray-200 pt-8">
              <span dangerouslySetInnerHTML={{ __html: section?.title || '' }} />
              <span className="flex items-center">
                {open ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 4.5a.75.75 0 01.75.75v6h6a.75.75 0 010 1.5h-6v6a.75.75 0 01-1.5 0v-6h-6a.75.75 0 010-1.5h6v-6A.75.75 0 0112 4.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </span>
            </Disclosure.Button>
            <Disclosure.Panel as="div" className="mt-2 pr-12 text-gray-600 text-sm">
              <div 
                dangerouslySetInnerHTML={{ __html: section?.description || '' }}
                className="prose prose-sm max-w-none"
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    ))}
  </>
)}
          </dl>
        </div>
      </section>

          {showRelatedProducts && (
            <div ref={relatedProductsRef} className="mt-10">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-gray-900">Generische Produkte</h2>
                <button
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-[#568774] hover:bg-[#3A7861] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#568774] whitespace-nowrap"
                  onClick={() => {
                    const genericCode = product?.articles[0]?.genericGroupCodes?.[0];
                    if (genericCode) {
                      navigate(`/products?genericCode=${genericCode}&level=substancegroup&view=generic`);
                    }
                  }}
                >
                  Mehr generische Produkte anzeigen
                </button>
              </div>
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 mt-8">
                {relatedProducts.map((relatedProduct) =>
                  relatedProduct.articles.map((article) => {
                    // Determine the image URL, prioritizing PICFRONT images
                    const images = article.images?.M || []; // Get the 'M' folder images
                    const picFrontImage = images.find((image) => image.includes('PICFRONT')); // Find PICFRONT image
                    const imageUrl = picFrontImage
                      ? `https://pharma-outlet.com/uploads/${picFrontImage.replace(/\\/g, '/')}`
                      : images.length > 0
                      ? `https://pharma-outlet.com/uploads/${images[0].replace(/\\/g, '/')}`
                      : null; // If no images are available, imageUrl will be null
                    

                    return (
                      <div
                        key={article.pharmacode}
                        onClick={() => {
                          navigate(`/product/${article.pharmacode}`);
                          setShowRelatedProducts(false); // Hide the related products list
                        }}
                        className="group relative border border-gray-200 rounded-lg bg-white shadow-sm p-4 flex flex-row lg:flex-col hover:shadow-lg transition-shadow duration-200 cursor-pointer"
                      >
                        <div className="flex-shrink-0 w-32 h-32 mr-4 lg:w-full lg:h-64 lg:mb-4">
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              alt={article.description?.longDescription || 'Product Image'}
                              className="h-full w-full object-contain"
                            />
                          ) : (
                            <div className="h-full w-full flex items-center justify-center text-gray-500">
                              <span>No Image Available</span>
                            </div>
                          )}
                        </div>

                        <div className="flex-grow flex flex-col justify-between text-left lg:text-center">
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">
                              {article.description?.description || 'No Description Available'}
                            </h3>
                            <p className="italic text-gray-500 line-clamp-2">
                              {article.description?.longDescription || 'No Details Available'}
                            </p>
                            {article.prices && article.prices.length > 0 ? (
                              <p className="mt-2 font-medium text-gray-900">
                                CHF{' '}
                                {Number(article.prices[0].price).toLocaleString('en-CH', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            ) : (
                              <p className="mt-2 font-medium text-gray-900">Price not available</p>
                            )}
                          </div>

                          {/* Regulation-based shipping information */}
                          {article.regulations?.length > 0 && (
                            <div className="mt-2 text-sm flex items-center justify-start lg:justify-center">
                              {article.regulations[0].code.id === 'Sale.Web.Rx' && (
                                <div className="flex items-center">
                                  <p className="text-[#E05257]">Shipping not possible</p>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-[#E05257]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                              <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-16.5a.75.75 0 01-.75-.75v-2.5zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                            </svg>
                                </div>
                              )}
                              {article.regulations[0].code.id === 'Sale.Web.CC' && (
                                <div className="flex items-center">
                                  <p className="text-[#E05257]">Shipping not possible</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 mr-1 text-[#E05257]"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                  >
                                    <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75v-2.5h-16.5a.75.75zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                                  </svg>
                                </div>
                              )}
                              {article.regulations[0].code.id === 'Sale.Web.OK' && (
                                <div className="flex items-center">
                                  <p className="text-[#4D7F6E]">Shipping possible</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 mr-1 text-[#4D7F6E]"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                  >
                                    <path d="M3 3.75A.75.75 0 013.75 3h16.5a.75.75v-2.5h-16.5a.75.75zM4.5 10.5a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v7.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V10.5z" />
                                  </svg>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}