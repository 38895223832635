// src/components/Shop/CategorySidebar.js
import { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { fetchCategories } from '../../services/apiService';

const staticCategories = [
  // High Priority (Essential Health Needs & Common Ailments)
  "Schmerzen & Fieber",
  "Erkältung & Grippe",
  "Magen & Darm",
  "Herz-Kreislauf",
  "Allergie & Heuschnupfen",

  // Medium Priority (Preventative & General Well-being)
  "Vitamine & Nahrungsergänzungsmittel",
  "Hygiene & Desinfektion",
  "Muskeln & Gelenke",
  "Frauen­gesundheit",
  "Nieren, Blase & Prostata",

  // Lower Priority (Specific Needs & Lifestyle)
  "Haut, Haare & Nägel",
  "Sinne & Pflege",
  "Haut & Verletzungen",
  "Mutter & Kind",
  "Sex & Verhütung",
  "Tests & Messgeräte",
  "Ernährung",
  "Hilfsmittel & Werkzeuge",
  "Tiergesundheit",
];


export default function CategorySidebar() {
  const navigate = useNavigate();
  const [fetchedCategories, setFetchedCategories] = useState({});
  const [openCategory, setOpenCategory] = useState(null);

  useEffect(() => {
    const fetchAndMapCategories = async () => {
      try {
        const response = await fetchCategories();
        const fetchedData = response.data;

        const categoryMapping = {
          // High Priority (Essential Health Needs & Common Ailments)
          "Schmerzen & Fieber": ["PainFever"],  
          "Erkältung & Grippe": ["FluColds"],  
          "Magen & Darm": ["StomachIntestines"],  
          "Herz-Kreislauf": ["Cardiovascular"],  
          "Allergie & Heuschnupfen": ["AllergyHayfever"],  
        
          // Medium Priority (Preventative & General Well-being)
          "Vitamine & Nahrungsergänzungsmittel": ["VitaminsSupplements"],  
          "Hygiene & Desinfektion": ["HygieneDisinfection"],  
          "Muskeln & Gelenke": ["MusclesJoints"],  
          "Frauen­gesundheit": ["WomensHealth"],  
          "Nieren, Blase & Prostata": ["KidneysBladderProstate"],  
        
          // Lower Priority (Specific Needs & Lifestyle)
          "Haut, Haare & Nägel": ["SkinHairNails"],  
          "Sinne & Pflege": ["MouthThroatTeeth", "EyesEars"],  
          "Haut & Verletzungen": ["SkinInjuries"],  
          "Mutter & Kind": ["MotherChild"],  
          "Sex & Verhütung": ["SexContraception"],  
          "Tests & Messgeräte": ["TestsMeasurements"],  
          "Ernährung": ["Nutrition"],  
          "Hilfsmittel & Werkzeuge": ["AidsTools"],  
          "Tiergesundheit": ["Animals"]  
        };
        

        const mappedCategories = {};
        Object.keys(categoryMapping).forEach((staticCategory) => {
          const relevantCategories = categoryMapping[staticCategory];
          const matchingCategories = fetchedData.filter((category) =>
            relevantCategories.includes(category.category.en)
          );
          mappedCategories[staticCategory] = matchingCategories;
        });

        setFetchedCategories(mappedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchAndMapCategories();
  }, []);

  const handleCategoryClick = (subcategory) => {
    const cleanSubcategoryId = subcategory.en.trim();
    sessionStorage.setItem('germanName', subcategory.de);
    navigate(`/categories/${cleanSubcategoryId}/products`, {
      replace: true
    });
  };
  
  const toggleCategory = (categoryName) => {
    setOpenCategory(openCategory === categoryName ? null : categoryName);
  };

  return (
    <div className="hidden lg:block lg:col-span-1">
    <h3 className="sr-only">Categories</h3>
    <ul className="space-y-4 border-b border-gray-200 pb-6 text-md font-medium text-[#4D7F6E]">
      <li key="all">
        <a href="/products">All Products</a>
      </li>
    </ul>

      {staticCategories.map((categoryName) => (
        <Disclosure key={categoryName} as="div" className="border-b border-gray-200 py-6">
          <h3 className="-my-3 flow-root">
            <Disclosure.Button 
              className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500"
              onClick={() => toggleCategory(categoryName)}
            >
              <span className="font-medium text-gray-900 text-left">{categoryName}</span>
              <span className="ml-6 flex items-center flex-shrink-0">
                {openCategory === categoryName ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20 12H4"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                )}
              </span>
            </Disclosure.Button>
          </h3>
          {openCategory === categoryName && (
            <Disclosure.Panel static className="pt-6">
              <div className="space-y-4">
                {fetchedCategories[categoryName]?.map((category, idx) => (
                  <ul key={idx} className="px-1 space-y-1">
                    {category.subcategories.map((subcategory, subIdx) => (
                      <li key={subIdx}>
                        <button
                          onClick={() => handleCategoryClick(subcategory)}
                          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-all duration-200 ease-out hover:pl-6 hover:text-[#4D7F6E]"
                        >
                          {subcategory.de}
                        </button>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </Disclosure.Panel>
          )}
        </Disclosure>
      ))}
    </div>
  );
}

export function useCategories() {
  const [categories, setCategories] = useState({});
  
  useEffect(() => {
    const fetchAndMapCategories = async () => {
      try {
        const response = await fetchCategories();
        const fetchedData = response.data;
        setCategories(fetchedData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchAndMapCategories();
  }, []);
  
  return categories;
}
