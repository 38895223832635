export default function VacDetails() {
  return (
    <div className="bg-indigo-100">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
        <h2 className="max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Haben Sie Fragen? <br />
          Kontaktieren Sie uns.
        </h2>
        <div className="mt-10 flex flex-col gap-y-4 lg:mt-0 lg:shrink-0">
          <div className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm">
          support@pharmacy.com
          </div>
          <div className="text-sm/6 font-semibold text-gray-900">
            Tel: +41 61 691 74 88
          </div>
        </div>
      </div>
    </div>
  )
}

