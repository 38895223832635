import React, { useEffect, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { fetchCategories } from '../services/apiService'; 
import { Link, useNavigate } from 'react-router-dom'; 
import { logoutUser } from '../services/apiService';

export default function Sidebar({ solutions, callsToAction, company, resources }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPopover, setCurrentPopover] = useState(null); 
  const [categories, setCategories] = useState([]); 
  const sidebarRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    
    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await logoutUser();
      localStorage.removeItem('token');
      navigate('/loginpage');
      setIsSidebarOpen(false);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = ''; 
    }

    return () => {
      document.body.style.overflow = ''; 
    };
  }, [isSidebarOpen]);

  const fetchSolutionCategories = async (solution) => {
    try {
      const response = await fetchCategories();
      const fetchedData = response.data;

      const categoryMapping = {
        "Medication & Treatment": ["PainFever", "FluColds"],
        "Nutrition & Health": ["Nutrition"],
        "Baby & Parents": ["MotherChild"],
        "Beauty & Personal Care": ["FacialSkinCareMakeUp", "SkinHairNails"],
        "Household & Cleaning": ["HouseGarden"],
        "Medical Supplies & Nursing Care": ["FirstAidDressingMat"]
      };

      const relevantCategories = categoryMapping[solution.name] || [];
      const matchingCategories = fetchedData.filter((category) =>
        relevantCategories.includes(category.category.en)
      );

      setCategories(matchingCategories); 
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleBackToMain = () => {
    setCurrentPopover(null); 
  };

  const handleSubcategoryClick = (subcategory) => {
    const cleanSubcategoryId = subcategory.en.trim();
    sessionStorage.setItem('germanName', subcategory.de);
    navigate(`/categories/${cleanSubcategoryId}/products`);
    setIsSidebarOpen(false);
    setCurrentPopover(null);
  };

  const handlePanelClose = () => {
    setIsSidebarOpen(false);
  };

  return (
    <Popover.Panel
      className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition-all duration-300 ease-out data-[state=closed]:scale-95 data-[state=closed]:opacity-0 custom:hidden max-h-[100vh] overflow-y-auto overflow-x-hidden min-h-[100vh]"
    >
      {({ close }) => (
        <div
          className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-xl ring-1 ring-black/5 min-h-[100vh] pt-safe-bottom pb-[env(safe-area-inset-bottom)]"
          ref={sidebarRef}
        >
          {currentPopover ? (
            <div>
              <div className="flex items-center p-5 border-b border-gray-100">
                <button 
                  onClick={handleBackToMain} 
                  className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M15.78 3.28a.75.75 0 010 1.06L9.06 11l6.72 6.72a.75.75 0 11-1.06 1.06l-7-7a.75.75 0 010-1.06l7-7a.75.75 0 011.06 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <h2 className="ml-4 text-lg font-medium text-gray-900">{currentPopover.name}</h2>
              </div>

              <div className="p-10">
                {categories.length > 0 ? (
                  categories.map((category) => (
                    <div key={category._id} className="mb-4">
                      <ul className="space-y-5">
                        {category.subcategories.map((subcategory, subIdx) => (
                          <li key={subIdx}>
                            <button
onClick={() => {
  const cleanSubcategoryId = subcategory.en.trim();
  sessionStorage.setItem('germanName', subcategory.de);
  navigate(`/categories/${cleanSubcategoryId}/products`, {
    replace: true
  });
  setIsSidebarOpen(false);
  setCurrentPopover(null);
  close();
}}
                              className="text-lg text-gray-900 hover:text-[#53a751] transition-colors duration-200"
                            >
                              {subcategory.en}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No categories found.</p>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="px-5 pb-6 pt-5 sm:pb-8">
                <div className="flex items-center justify-between">
                  <div className="-mr-2">
                    <button
                      className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 hover:text-gray-500 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#53a751]"
                      onClick={() => {
                        close();
                        handlePanelClose();
                      }}
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Close menu</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path
                          fillRule="evenodd"
                          d="M6.225 6.225a.75.75 0 011.06 0L12 10.94l4.715-4.715a.75.75 0 011.06 1.06L13.06 12l4.715 4.715a.75.75 0 11-1.06 1.06L12 13.06l-4.715 4.715a.75.75 0 11-1.06-1.06L10.94 12 6.225 7.285a.75.75 0 010-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="mt-6 sm:mt-8">
                  <nav>
                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-8">
                      {solutions.map((item) => (
                        <div 
                          key={item.name} 
                          className="group flex items-center justify-between cursor-pointer hover:bg-gray-50 rounded-lg transition-colors duration-200 p-3"
                          onClick={() => {
                            setCurrentPopover(item);
                            fetchSolutionCategories(item);
                          }}
                        >
                          <div className="flex items-center space-x-4">
                            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-[#F6F7FF] transition-transform duration-200 group-hover:scale-105 sm:h-12 sm:w-12">
                              {item.icon}
                            </div>
                            <div className="text-base font-medium text-gray-900 group-hover:text-[#53a751] transition-colors duration-200">
                              {item.name}
                            </div>
                          </div>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400 group-hover:text-[#53a751] transition-colors duration-200" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                            <path
                              fillRule="evenodd"
                              d="M8.22 3.28a.75.75 0 011.06 0l7 7a.75.75 0 010 1.06l-7 7a.75.75 0 11-1.06-1.06L14.94 11 8.22 4.28a.75.75 0 010-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      ))}
                    </div>
                    <div className="mt-8 text-base">
                      <a href="/products" className="font-medium text-gray-900 transition-colors duration-200">
                        View all products
                        <span aria-hidden="true" className="ml-2">→</span>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          )}

          {!currentPopover && (
            <div className="px-5 py-6">
              {isLoggedIn && (
                <div className="mb-8">
                  <h3 className="text-base font-medium text-gray-900 mb-4">My Account</h3>
                  <div className="grid grid-cols-1 gap-4">
                    <Link 
                      to="/profilepage" 
                      className="flex items-center p-3 bg-gray-50 rounded-lg text-gray-600 hover:bg-gray-100 transition-all duration-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3 text-[#53a751]"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx="12" cy="7" r="4" />
                      </svg>
                      <span>Profile</span>
                    </Link>
                    
                    <Link 
                      to="/userfavoritespage" 
                      className="flex items-center p-3 bg-gray-50 rounded-lg text-gray-600 hover:bg-gray-100 transition-all duration-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3 text-[#53a751]"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
                      </svg>
                      <span>Favorites</span>
                    </Link>
                    
                    <Link 
                      to="/cartpage" 
                      className="flex items-center p-3 bg-gray-50 rounded-lg text-gray-600 hover:bg-gray-100 transition-all duration-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3 text-[#53a751]"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                      <span>Cart</span>
                    </Link>
                  </div>
                </div>
              )}

              <div className="grid grid-cols-2 gap-4">
                {company.map((item) => (
                  <a 
                    key={item.name} 
                    href={item.href} 
                    className="rounded-md text-base font-medium text-gray-900 hover:text-[#53a751] transition-colors duration-200"
                  >
                    {item.name}
                  </a>
                ))}
                {resources.map((item) => (
                  <a 
                    key={item.name} 
                    href={item.href} 
                    className="rounded-md text-base font-medium text-gray-900 hover:text-[#53a751] transition-colors duration-200"
                  >
                    {item.name}
                  </a>
                ))}
              </div>

              <div className="mt-6">
                {!isLoggedIn ? (
                  <>
                    <a
                      href="/registerpage"
                      className="flex w-full items-center justify-center rounded-full bg-[#F6F7FF] px-4 py-2 text-base font-medium text-gray-900 shadow-xl hover:bg-[#458b43] transition-colors duration-200"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <a href="/loginpage" className="text-gray-900 transition-colors duration-200">
                        Sign in
                      </a>
                    </p>
                  </>
                ) : (
                  <button
                    onClick={handleLogout}
                    className="flex w-full items-center justify-center rounded-md bg-[#51A550] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#458b43] transition-all duration-200 space-x-2"
                  >
                    <span>Logout</span>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-5 w-5"
                      viewBox="0 0 24 24" 
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1="21" y1="12" x2="9" y2="12" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Popover.Panel>
  );
}
