import { motion } from 'framer-motion';

const timeline = [
  {
    name: 'Online-Shop',
    description:
      'Entdecken Sie eine breite Palette an Gesundheitsprodukten, die online erhältlich sind. Bequemes und zuverlässiges Einkaufen ganz einfach.',
    date: (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
        <path
          d="M6.29977 5H21L19 12H7.37671M20 16H8L6 3H3M9 20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20ZM20 20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20Z"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dateTime: '2021-08',
  },
  {
    name: 'Labor-Dienstleistungen',
    description:
      'Wir bieten professionelle Labor-Dienstleistungen an, einschließlich Impfungen und diagnostischer Tests, um Ihre Gesundheit zu fördern.',
    date: (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
        <path
          d="M12.5 7.50006L16 4.00006C17.1046 2.89549 18.8954 2.89549 20 4.00006C21.1046 5.10463 21.1046 6.89549 20 8.00006L16.5 11.5001M11 6.00006L18 13.0001M14 14.0001L10.4059 17.5942C9.88703 18.113 9.6276 18.3725 9.32485 18.558C9.05644 18.7225 8.7638 18.8437 8.4577 18.9172C8.11243 19.0001 7.74555 19.0001 7.01178 19.0001H6L3 21.0001L5 18.0001V16.9883C5 16.2545 5 15.8876 5.08289 15.5424C5.15638 15.2363 5.27759 14.9436 5.44208 14.6752C5.6276 14.3725 5.88703 14.113 6.40589 13.5942L10 10.0001"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dateTime: '2021-12',
  },
  {
    name: 'Persönliche Apotheke',
    description:
      'Verwalten Sie Ihre persönlichen Einkaufsinformationen mühelos, speichern Sie Favoriten und genießen Sie ein nahtloses Einkaufserlebnis, das auf Ihre Bedürfnisse zugeschnitten ist.',
    date: (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
        <path
          d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dateTime: '2022-02',
  },
  {
    name: 'Erweitertes Produktsortiment',
    description:
      'Wir erweitern kontinuierlich unser Produktsortiment, um Ihnen eine umfassende Auswahl an Gesundheits- und Wellnessprodukten zu bieten.',
    date: (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
        <path
          d="M10 14H14M12 12V16M7 8H17V16.8C17 17.9201 17 18.4802 16.782 18.908C16.5903 19.2843 16.2843 19.5903 15.908 19.782C15.4802 20 14.9201 20 13.8 20H10.2C9.07989 20 8.51984 20 8.09202 19.782C7.71569 19.5903 7.40973 19.2843 7.21799 18.908C7 18.4802 7 17.9201 7 16.8V8ZM6 4H18V8H6V4Z"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dateTime: '2022-12',
  },
];

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const lineVariant = {
  initial: { width: 0 },
  animate: { width: '100%' },
  transition: { duration: 0.8, ease: "easeInOut" }
};

export default function Example() {
  return (
    <div className="py-16 sm:py-5 overflow-x-hidden lg:mt-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <motion.div 
          className="mx-auto max-w-2xl lg:mx-0"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Unsere Services
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Entdecken Sie die vielfältigen Dienstleistungen unserer Apotheke.
          </p>
        </motion.div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {timeline.map((item, index) => (
            <motion.div
              key={item.name}
              className="relative"
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              variants={fadeInUp}
            >
              <div className="relative">
                <motion.div 
                  className="flex items-center"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <motion.div 
                    className="flex h-14 w-14 items-center justify-center rounded-xl bg-indigo-50 shadow-sm hover:bg-indigo-100 transition-colors duration-300"
                    whileHover={{ y: -2 }}
                  >
                    {item.date}
                  </motion.div>
                  <motion.div
                    initial="initial"
                    animate="animate"
                    variants={lineVariant}
                    className="hidden lg:block absolute left-[3.5rem] top-7 h-[2px] w-[calc(100%-3.5rem)] bg-gradient-to-r from-indigo-200 to-transparent"
                  />
                </motion.div>
              </div>
              <motion.div 
                className="mt-6 p-6 rounded-xl bg-white shadow-sm hover:shadow-md transition-shadow duration-300"
                whileHover={{ y: -4 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">
                  {item.name}
                </h3>
                <p className="mt-2 text-base leading-7 text-gray-600">
                  {item.description}
                </p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}
