import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { searchProducts } from '../services/apiService';
import { motion, AnimatePresence } from 'framer-motion';

const SearchBar = ({ placeholder = 'Suchen...' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [previewResults, setPreviewResults] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowPreview(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm.trim().length >= 2) {
      setIsLoading(true);
      setShowPreview(true);

      const fetchResults = async () => {
        try {
          const response = await searchProducts(searchTerm, 1, 5);
          console.log('Search API Response:', response);
          if (response?.success && Array.isArray(response.data.products)) {
            console.log('Products:', response.data.products);
            setPreviewResults(response.data.products);
          }
        } catch (error) {
          console.error('Search preview failed:', error);
          setPreviewResults([]);
        } finally {
          setIsLoading(false);
        }
      };

      const timer = setTimeout(fetchResults, 150);
      return () => clearTimeout(timer);
    } else {
      setPreviewResults([]);
      setShowPreview(false);
    }
  }, [searchTerm]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/products?search=${encodeURIComponent(searchTerm)}`);
      setShowPreview(false);
    }
  };

  const handlePreviewClick = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
    setShowPreview(false);
    setSearchTerm('');
  };

  const getOptimizedImageUrl = (images) => {
    if (!Array.isArray(images) || images.length === 0) return null;
    
    const picFront3D = images.find(image => image.includes('PICFRONT3D'));
    if (picFront3D) {
      return picFront3D;
    }
    
    const picFront = images.find(image => image.includes('PICFRONT'));
    if (picFront) {
      return picFront;
    }
    
    return images[0];
  };

  return (
    <div ref={searchRef} className="relative w-full">
      <form onSubmit={handleSearchSubmit} className="relative w-full">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="block w-full bg-gray-50 rounded-full shadow-lg border-gray-100 py-3 pl-4 pr-12 text-sm placeholder-gray-700 focus:border-[#F6F7FF] focus:ring-[#F6F7FF]"
          placeholder={placeholder}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <button
            type="submit"
            className="flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
          >
            <FaSearch className="h-4 w-4 mr-2" aria-hidden="true" />
          </button>
        </div>
      </form>

      <AnimatePresence>
        {showPreview && searchTerm.trim().length >= 2 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-50 w-full mt-2 bg-white rounded-lg shadow-xl border border-gray-200"
            style={{ maxHeight: '480px' }}
          >
            {isLoading ? (
              <div className="p-6 text-center">
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-green-500 border-r-transparent"></div>
              </div>
            ) : previewResults.length > 0 ? (
              <motion.div 
                className="py-2 divide-y divide-gray-50"
                initial="hidden"
                animate="visible"
                variants={{
                  visible: {
                    transition: {
                      staggerChildren: 0.05
                    }
                  }
                }}
              >
                {previewResults.map((product) => {
                  console.log('Price debug:', {
                    directPrice: product.prices?.price,
                    articlePrice: product.articles?.[0]?.prices?.price,
                    fullPricesObject: product.prices,
                    fullArticlesObject: product.articles
                  });
                  const article = {
                    pharmacode: product._id,
                    description: {
                      description: product.description?.description || 'No description available',
                      longDescription: product.description?.longDescription || product.longDescription || 'No description available'
                    },
                    hasPhoto: product.hasPhoto || false,
                    images: {
                      M: Array.isArray(product.images) ? product.images.map(img => img.replace(/^uploads\\/, '')) : []
                    },
                    prices: product.prices?.price || 
                           (product.articles?.[0]?.prices?.price) || 
                           null
                  };
                  console.log('Processed article:', article);

                  return (
                    <motion.div
                      key={product._id}
                      variants={{
                        hidden: { opacity: 0, y: 10 },
                        visible: { opacity: 1, y: 0 }
                      }}
                      whileHover={{ scale: 1.02 }}
                      onClick={() => handlePreviewClick(product._id)}
                      className="px-4 py-3 hover:bg-gray-50 cursor-pointer flex items-center space-x-4 transition-colors duration-200"
                    >
                      <div className="w-16 h-16 flex-shrink-0 rounded-lg overflow-hidden">
                        {article.hasPhoto && article.images.M.length > 0 ? (
                          <img
                            src={`https://pharma-outlet.com/uploads/${getOptimizedImageUrl(article.images.M).replace(/\\/g, '/')}`}
                            alt={article.description.description}
                            className="w-full h-full object-contain"
                          />
                        ) : (
                          <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                            <svg className="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 line-clamp-2">
                          {article.description.longDescription}
                        </p>
                        <p className="mt-1 text-sm font-small text-green-600">
                          {article.prices ? `CHF ${article.prices}` : 'Preis nicht verfügbar'}
                        </p>
                      </div>
                    </motion.div>
                  );
                })}
              </motion.div>
            ) : (
              <div className="p-6 text-center text-gray-500">
                Keine Ergebnisse gefunden für "{searchTerm}"
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchBar;